import React, { useLayoutEffect, useState, useEffect } from "react";
import queryString from 'query-string';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import VideoCameraBack from '@mui/icons-material/VideoCameraBack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { axiosGet, axiosPost } from "../../apis/axios";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Metarex
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const getCurrentTimestampAsSeconds = () => {
    return Math.floor(Date.now() / 1);
}

const BoraCamera = () => {

    const parsed = queryString.parse(window.location.search);
    const url = parsed.type;

    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const [ latestAddBoraCamera, setLatestAddBoraCamera] = useState( getCurrentTimestampAsSeconds() );

	useEffect(()=>{
        const coordinates = latitude.split(',');
        if( longitude === '' ) {
            if( coordinates.length === 2 ) {
                console.log('thkim test 20230417 %o', coordinates);
                setLatitude( coordinates[0].trim() );
                setLongitude( coordinates[1].trim() );
            }
        }
	}, [latitude]);


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const json =
        {
          'map-share-link': data.get('map-share-link'),
          latitude: data.get('latitude'),
          longitude: data.get('longitude'),
          address: data.get('address'),
          alias: data.get('alias'),
          'youtube-url': data.get('youtube-url'),

        }
    console.log(json);

    const currentTimestamp = getCurrentTimestampAsSeconds();
    const requestOffsetSeconds = 900;
    console.log(`${latestAddBoraCamera + requestOffsetSeconds}____${currentTimestamp}`);
    if( latestAddBoraCamera + requestOffsetSeconds < currentTimestamp ) {
        addBoraCamera( json );
        setLatestAddBoraCamera( getCurrentTimestampAsSeconds() );
    }

  };



    const addBoraCamera = ( json ) => {
        axiosPost('api/item-management/add-bora-camera', json)
        .then(response => {
            if(!(JSON.parse(response.data.result))) {
                alert(response.data.message);
                return;
            } else {
                alert( '처리가 완료 되었습니다.' );
            }
        }).catch(e => {
            console.log(`sign in error : ${e}`);
        });
    }




  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <VideoCameraBack />
          </Avatar>
          <Typography component="h1" variant="h5">
            보라 카메라 추가하기
          </Typography>
          <Grid container spacing={3}>
              <Grid item xs={12}>
                <Link href="https://map.naver.com/v5/?c=14145146.920990927,4581653.060511284,15,0,0,0,dh" variant="body2">
                네이버 지도 바로가기
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href="https://map.kakao.com/link/map/38.015764592595815-127.06801675242339,38.015764592595815,127.06801675242339" variant="body2">
                카카오 지도 바로가기
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href="http://www.google.com/maps/place/38.015764592595815,127.06801675242339" variant="body2">
                구글 지도 바로가기
                </Link>
              </Grid>
            </Grid>



          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="map-share-link"
                  label="맵 공유 링크"
                  name="map-share-link"
                  autoComplete="map-share-link"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="latitude"
                  name="latitude"
                  required
                  fullWidth
                  id="latitude"
                  label="위도"
                  value={latitude} onChange={e => setLatitude(e.target.value)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="longitude"
                  label="경도"
                  value={longitude} onChange={e => setLongitude(e.target.value)}
                  name="longitude"
                  autoComplete="longitude"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="주소"
                  name="address"
                  autoComplete="address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="alias"
                  label="별칭"
                  name="alias"
                  autoComplete="alias"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="youtube-url"
                  label="Youtube URL"
                  name="youtube-url"
                  autoComplete="youtube-url"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              추가
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="https://xn--yq5bk9r.com/blog/map-coordinates" target="_blank" rel="noopener noreferrer" variant="body2">
                  Map URL을 위도 경도로 변경하는 online tool
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

export default BoraCamera;
