import React from 'react';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { axiosPost } from '../../apis/axios';
import SendBoard from '../sendBoard/SendBoard';
import './SendEmail.css'

const SendEmail = () => {

    const [emailInputs, setEmailInputs] = useState("");
    // modal toggle
    const [emailBoard , setEmailBoard] = useState(false)
    // emailsage user 
    const [emailUser , setEmailUser] = useState("")

    // INPUT값 넣기
    const [emailTitle, setEmailTitle] = useState("");
    const [emailText, setEmailText] = useState("");
    const [emailPhone, setEmailPhone] = useState("");
    const [emailName , setEmailName] = useState("")
    const [emails , setEmails] = useState("")

    const [ userRemoveText, setUserRemoveText ] = useState(false)

    const userRemoveBtn = (e) => {
        e.preventDefault()
        setUserRemoveText(true)
    }
    
    // 버튼 클릭시 밑의 문구가 출력된다.
    const userRemoveareaText = () => {
        return "데이터 삭제 및 탈퇴처리가 모두 완료되었습니다. 이용해주셔서 감사합니다."
    }

    const emailToggle = (e) => {
        e.preventDefault();
        setEmailBoard(true)
    }

    const emailSend = (e) => {
        e.preventDefault()
        let phone = emailUser?.mobile ? emailUser?.mobile : emailPhone
        let name = emailUser?.nameKr ? emailUser?.nameKr : emailName
        let email = emailUser?.email ? emailUser?.email : emails

        if(emailTitle === ""){
            alert("제목을 입력하세요")
        }else if((emailUser?.email ? emailUser?.email : emails) === "" ){
            alert("이메일을 입력하세요")
        }else if(((emailText === "" && userRemoveText === true) ? userRemoveareaText() : emailText) === ""){
            alert("내용을 입력하세요")
        }else if(window.confirm(
            `이메일을 ${name}(${phone}) ${email} 고객에게 아래 내용으로 전송하시겠습니까?` + `\n` +
            `\n` +
            `${emailTitle}` + `\n`  +
            `${(emailText === "" && userRemoveText === true) ? userRemoveareaText() : emailText}`)){
                axiosPost(`send/email/sending`, {
                    'content' : (emailText === "" && userRemoveText === true) ? userRemoveareaText() : emailText,
                    'subject' : !emailTitle ? "" : emailTitle,
                    'userMobile' : emailUser?.mobile ? emailUser?.mobile : emailPhone,
                    'userEmail' : emailUser?.email ? emailUser?.email : emails
                })
                .then(response => {
                    if(response){
                        // alert(response.data.emailsage);
                    }
                })
                .catch(error => console.error(error));
        }
    }
    

    return (
        <section className='emailContainer'>
            <form action="">
                <ul>
                    <li>
                        <h3>이메일 발송하기</h3>
                    </li>
                    <li>
                        <label>제목</label>
                        <input name="제목" type="text" onChange={(e) => setEmailTitle(e.target.value)} defaultValue="[메타렉스]" autoComplete="off"/>
                    </li>
                    <li>
                        <label>회원번호</label>
                        <input name="회원번호" type="text" autoComplete="off" defaultValue={emailUser?.userNo} disabled/> 
                    </li>
                    <li>
                        <label>이름</label>
                        <input name="이름" type="text" placeholder="회원을 선택해 주세요." autoComplete="off" onChange={(e)=>setEmailName(e.target.value)} defaultValue={emailUser?.nameKr} disabled/>
                    </li>
                    <li>
                        <label>휴대폰번호</label>
                        <input name="휴대폰번호" type="text" placeholder="회원을 선택해 주세요." autoComplete="off" onChange={(e)=>setEmailPhone(e.target.value)} defaultValue={emailUser?.mobile} disabled/>
                    </li>
                    <li>
                        <label>수신자 이메일</label>
                        <input name="수신자 이메일" type="text" onChange={(e) => setEmails(e.target.value)} placeholder="이메일을 입력해 주세요." defaultValue={emailUser?.email}  autoComplete="off"/>
                    </li>
                    <li>
                        <button onClick={emailToggle}>회원 선택</button>
                    </li>
                    <li>
                        <label>내용</label>
                        {/* <textarea name="내용" type="text" onChange={(e) => setEmailText(e.target.value)} */}
                        <textarea name="내용" type="text" onChange={(e) => setEmailText(e.target.value)}
                        defaultValue = {
                            userRemoveText === true ? userRemoveareaText() : ""    
                        }>
                        </textarea>
                    </li>
                    <li>
                        <button onClick={emailSend}>전송</button>
                    </li>
                    <li>
                        <hr />
                    </li>
                    <li>
                        <button onClick={userRemoveBtn}>회원탈퇴 메시지 불러오기</button>
                    </li>
                </ul>
            </form>
            {
                emailBoard === true &&
                // 회원선택 눌렀을시 이메일과 문자를 따로 설정해 bloean값을 보내준다. 
                <SendBoard setEmailBoard={setEmailBoard} emailBoard={emailBoard} setEmailUser={setEmailUser}></SendBoard>
            }
        </section>
    );
};

export default SendEmail;