import axios from "axios";

// 또는 공통으로 추가(same-site 관련_cors)
// axios.defaults.withCredentials = true;

export const axiosPost = async (UrlParam, json) => {
    const url = `${process.env.REACT_APP_API_METAREX}/${UrlParam}`;
    console.log(url);
    const instance = axios.create({
        withCredentials: true
    })
    return await instance.post(url, json);
}

export const axiosGet = async (UrlParam) => {
    const url = `${process.env.REACT_APP_API_METAREX}/${UrlParam}`;
    return await axios.get(url, {withCredentials : true});
}