import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { axiosGet } from "../../apis/axios";
import PageTemplate from "../../components/PageTemplate";
import ItemCard from "../../components/payment/ItemCard";
import P2pApproval from "../../contents/payments/P2pApproval";
import { MOCK_DATA_FOR_P2P_REQUEST } from "../../mockData";

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    >p{
        display: inline;
    }
`;

const P2pRequestApproval = () => {
    const [requestList, setRequestList] = useState([]);
    useEffect(() => {
        let p2pApprovalUrl = `asterpay/history/getP2PNoChecking?pageNum=1&numPerPage=10`;
        axiosGet(p2pApprovalUrl)
        .then(res => setRequestList(res.data))
        .catch(err => console.log(err))
        // setRequestList(MOCK_DATA_FOR_P2P_REQUEST);
    },[]);

    return (
        <PageTemplate>
            <TitleDiv>                
                <p>P2P 매물 결제 승인처리</p>
                <span>'입금 완료 처리' 또는 '결체 취소 처리'를 진행해주세요.</span>
            </TitleDiv>
            {
                requestList?.data?.length === 0 ?
                <h2>
                    매물이 없습니다.
                </h2>
                : 
                requestList?.data?.map((el,index) => <ItemCard key={index}><P2pApproval data={el}/></ItemCard>)
            }
        </PageTemplate>
    );
}

export default P2pRequestApproval;