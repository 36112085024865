import React, { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { axiosGet} from "../../../apis/axios";
import SupportTemplate from "../../../components/SupportTemplate";
import UploadData from "../../../components/UploadData";
import ItemTemplate from "../../../components/ItemTemplate";

const SubTitle = styled.legend`
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
`;


const VideoListTemplate = styled.fieldset`
    width: 100%;
    max-width: 777px;
    border: 1px solid #ccc;
    padding: 40px;
    margin-bottom: 50px;
`;

const Videos = () => {
    const [items, setItems] = useState([]);


    useLayoutEffect(()=>{
        getVideoList();
    },[]);

    const getVideoList = () => {
        axiosGet('videos/select')
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setItems(response.data.data);
                    return;
                }
                alert(response.data.message);
            })
            .catch(error => {
                console.error(error);
            })
    }




    return (
        <SupportTemplate>
            <h1>자료실</h1>
            <div>
                <UploadData subTitle="자료실" requestUrl='videos'/>
                <VideoListTemplate>
                    <SubTitle>동영상 목록</SubTitle>
                    {
                        items.length !== 0 &&
                        items.map((el, index) => <ItemTemplate key={index} no={el.boardMetarexVideoNo} title={el.subjectKr} uri={el.uri} resourceUrl="videos"/>)
                    }
                </VideoListTemplate>
            </div>
        </SupportTemplate>
    );
}

export default Videos;