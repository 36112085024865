import React, { useState } from "react";
import styled from "styled-components";
import { axiosPost } from "../apis/axios";
import { isNull } from "../apis/common";
import Button from "./Button";

const Template = styled.div`
    border: 1px solid #ccc;
    margin: 5px 0px;
    >div{
        width:1100px;
        display:flex;
        align-items: center;
        justify-content: flex-end;
        >.number{
            width: 70px;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 15px;
            font-weight: 700;
            line-height: 14px;
            text-align: left;
            padding-left: 15px;
            border-right: 1px solid #ccc;
        }
        >.subject{
            width: calc(1100px/4);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0px 20px;
            border-right: 1px solid #ccc;
        }
        >.url{
            width: calc(1100px/2);
            padding-left: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        >.pressed_date{
            width: 150px;
            padding: 0px 0px;
            text-align:right;
        }

    }
`;

const InformationInput = styled.input`
    height: 48px;
    border: 1px solid #D9DDE2;
    border-radius: 2px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px 10px;
    border: none;
    &:disabled {
        background: #fff;
        color: #ccc;
    }
`;

const ItemTemplate = ({no, title, date, uri, resourceUrl}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [titleText, setTitleText] = useState(title);  
    const [itemUri, setItemUri] = useState(uri);
    const [pressedDate, setPressedDate] = useState(date);

    const updateData = () => {
        if(isNull(titleText) || titleText === ''){
            alert('제목을 입력해주세요.');
            return;
        }

        if(isNull(itemUri) || itemUri === ''){
            alert('url을 입력해주세요.');
            return;
        }

        if (window.confirm(`해당 [${no}번]의 정보를 수정하시겠습니까?`)) {
            let json = {}
            if(resourceUrl === 'hotIssue') {
                if(isNull(pressedDate) || pressedDate === ''){
                    alert('날짜를 선택해주세요.');
                    return;
                }
                json = {
                    'idx' : no,
                    'subjectKr': titleText,
                    'uri' : itemUri,
                    'pressDate': pressedDate
                }   
            } else if(resourceUrl === 'videos'){
                json = {
                    'idx' : no,
                    'subjectKr': titleText,
                    'uri' : itemUri
                }
            }
            axiosPost(`${resourceUrl}/update`, json)
                .then(response => {
                    if(JSON.parse(response.data.result)){
                        setIsEdit(false);
                    }             
                })
                .catch(error => console.error(error));
            console.log(titleText, itemUri);
        }
    }

    const deleteData = () => {
        if (window.confirm(`해당 비디오 [${titleText}] 를 삭제하시겠습니까?`)) {
            axiosPost(`${resourceUrl}/delete`, {'idx': no})
                .then(response => {
                    alert(response.data.message);
                    if(JSON.parse(response.data.result)){
                        window.location.href = `/${resourceUrl}`;
                        return;
                    }
                })
                .catch(error => console.error(error));
        }
    }

    const cancelModified = () => {
        setIsEdit(false); 
        setTitleText(title); 
        setItemUri(uri);
        setPressedDate(date);
    }

    return (
        <Template>
            <div>
                <span className="number">{no}</span>
            {
                !isEdit 
                        ?
                        <>
                        <InformationInput type="text" className="subject" value={titleText} disabled={!isEdit}/>
                        <InformationInput type="text" className="url" value={itemUri} disabled={!isEdit}/>
                        {
                            resourceUrl === 'hotIssue' &&
                                <InformationInput type="date" className="pressed_date" value={pressedDate} disabled={!isEdit}/>
                        }
                        <Button width="100px" onClick={() => setIsEdit(true)} style={{marginRight: "5px"}}>수정</Button>
                        <Button width="100px" onClick={deleteData}>삭제</Button>
                        </>
                        :
                        <>
                        <InformationInput type="text" className="subject" value={titleText} onChange={e => setTitleText(e.target.value)}/>
                        <InformationInput type="text" className="url" value={itemUri} onChange={e => setItemUri(e.target.value)}/>
                        {
                            resourceUrl === 'hotIssue' &&
                                <InformationInput type="date" className="pressed_date" value={pressedDate} onChange={e => setPressedDate(e.target.value)}/>
                        }
                        <Button width="100px" onClick={updateData} style={{marginRight: "5px", backgroundColor: "#3D85F0"}}>수정등록</Button>
                        <Button width="100px" onClick={cancelModified}>취소</Button>
                        </>
            }
            </div>
        </Template>
    );
}

export default ItemTemplate;