import React, { useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "./Dropdown";
import DrawerMenu from "../DrawerMenu";
import Logout from "./Logout";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../../redux/menu/actions";

const NavigationTemplate = styled.div`
    width:100%;
    height:100%;
    display: flex;
    justify-content: space-between;
    >.wrap{
        width: 100%;
        display: flex;
    }
    .menu{
        height:100%;
        width:100%;
        display:flex;
        // 이 부분 추가
        z-index:2;
        justify-content:center;
        >div {
            display:inherit;
            >li{
                display:inherit;
                align-items:center;
                justify-content:center;
                width:150px;
                height:100%;
                color:#fff;
                cursor:pointer;
                >a{
                    width:100%;
                    height:100%;
                    color:#fff;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                }
            }
            /* &:last-child{
                margin-left: auto;
            } */
        }
    }
    @media only screen and (max-width: 768px) {
        >.wrap{
            position:absolute;
            background:#05141F;
            top:60px;
            left:0;
            width:100%;
            height: auto;
            flex-direction:column;
            display: none;
            >.menu {
                flex-direction:column;
                >div{
                    display: block;
                    >li{
                        display: flex;
                        width:100%;
                        height:60px;
                    }
                }
            }
            &.active{
                display: flex;
            }
        }
    }
`;

const MobileButtonTemplate = styled.div`
    display: none;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    @media screen and (max-width:786px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const Navigation = () => {
    const [isActive, setIsActive] = useState({
                                        site: false,
                                        realestate: false,
                                        payment: false,
                                        cryptocurrency: false,
                                        users: false,
                                        message: false
                                    });
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    const clickOutsideHeader = useSelector(state => state.toggleReducer.data);
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        if(clickOutsideHeader){
            setMobileMenuActive(false);     // 모바일 메뉴 리셋
            resetActive();                  // 카테고리 메뉴 리셋
            dispatch(toggleMenu(false));    // reset redux
        }
    }, [clickOutsideHeader]);

    const onClick = (e) => {
        const newActive = resetActive();
        const menuTarget = e.currentTarget.dataset.dropdown.trim();
        setIsActive({...newActive, [menuTarget] : !isActive[menuTarget]});
    }

    const resetActive = () => {
        const newActive = {};
        const keys = Object.keys(isActive);
        keys.forEach(el => newActive[el] = false);
        setIsActive(newActive);
    }

    const clickMobileButton = () => {
        resetActive();
        setMobileMenuActive(!mobileMenuActive);
    }

    return (
        <>
        <NavigationTemplate>
            <div className={`wrap${mobileMenuActive ? " active" : ""}`}>
                <ul className="menu">
                    <div>
                        <li onClick={onClick} data-dropdown="site">
                            <span>사이트 관리 <FontAwesomeIcon icon={isActive['site'] ? faCaretUp : faCaretDown}/> </span>
                        </li>
                        {isActive['site'] && 
                            <Dropdown>
                                <li onClick={resetActive}><Link to="/hotIssue">부동산 핫이슈</Link></li>
                                <li onClick={resetActive}><Link to="/faq">FAQ</Link></li>
                                <li onClick={resetActive}><Link to="/videos">자료실</Link></li>
                                <li onClick={resetActive}><Link to="/main_video">메인 페이지</Link></li>
                            </Dropdown>
                        }
                    </div>
                    
                    <div>
                        <li onClick={onClick} data-dropdown="realestate">
                            <span>매물 관리 <FontAwesomeIcon icon={isActive['realestate'] ? faCaretUp : faCaretDown}/> </span>
                        </li>
                        {isActive['realestate'] && 
                            <Dropdown>
                                <li onClick={resetActive}><Link to="/recommendation">매물 등록</Link></li>
                                <hr/>
                                <li onClick={resetActive}><Link to="/approval">사용자 자율구매 매물 승인</Link></li>
                                <hr/>
                                <li onClick={resetActive}><Link to="/add-bora-camera">보라카메라 등록</Link></li>
                                <li onClick={resetActive}><Link to="/soldout-item-list">팔린 매물 보기</Link></li>
                                <li onClick={resetActive}><Link to="#" onClick={e=>alert('해당 서비스는 오픈 준비 중입니다.')}>매물 통계</Link></li>
                            </Dropdown>
                        }
                    </div>

                    <div>
                        <li onClick={onClick} data-dropdown="payment">
                            <span>결제 관리 <FontAwesomeIcon icon={isActive['payment'] ? faCaretUp : faCaretDown}/> </span>
                        </li>
                        {isActive['payment'] && 
                            <Dropdown>
                                <li onClick={resetActive}><Link to="item-point-purchase-processing" >Point 구매 승인처리</Link></li>
                                <li onClick={resetActive}><Link to="item-point-payment-and-deduction" >Point 지급 및 차감</Link></li>
                                <li onClick={resetActive}><Link to="item-point-withdraw-processing" >Point 출금 처리</Link></li>
                                <li onClick={resetActive}><Link to="item-point-payment-history" >Point 관리</Link></li>
                                <hr/>
                                <li onClick={resetActive}><Link to="item_payment" >매물 결제 승인처리</Link></li>
                                <li onClick={resetActive}><Link to="item_expired" >매물 결제 만료처리</Link></li>
                                <li onClick={resetActive}><Link to="item_history" >매물 결제 관리</Link></li>
                                <hr/>
                                <li onClick={resetActive}><Link to="#" onClick={e=>alert('해당 서비스는 오픈 준비 중입니다.')}>결제 통계</Link></li>
                            </Dropdown>
                        }
                    </div>

                    <div>
                        <li onClick={onClick} data-dropdown="cryptocurrency">
                            <span>암호화폐 관리 <FontAwesomeIcon icon={isActive['cryptocurrency'] ? faCaretUp : faCaretDown}/> </span>
                        </li>                    
                        {isActive['cryptocurrency'] && 
                            <Dropdown>
                                <li onClick={resetActive}><Link to="#" onClick={e=>alert('해당 서비스는 오픈 준비 중입니다.')}>비트글로벌 입금 내역</Link></li>
                                <li onClick={resetActive}><Link to="#" onClick={e=>alert('해당 서비스는 오픈 준비 중입니다.')}>한빗코 입금 내역</Link></li>
                                <li onClick={resetActive}><Link to="#" onClick={e=>alert('해당 서비스는 오픈 준비 중입니다.')}>암호화폐 통계</Link></li>
                            </Dropdown>
                        }
                    </div>

                    <div>
                        <li onClick={onClick} data-dropdown="users">
                            <span>회원 관리 <FontAwesomeIcon icon={isActive['users'] ? faCaretUp : faCaretDown}/> </span>
                        </li>
                        {isActive['users'] && 
                            <Dropdown>
                                <li onClick={resetActive}><Link to="/send_board">전체 회원</Link></li>
                                <li onClick={resetActive}><Link to="#" onClick={e=>alert('해당 서비스는 오픈 준비 중입니다.')}>구매 회원</Link></li>
                                <li onClick={resetActive}><Link to="/questions">문의 내역</Link></li>
                                <li onClick={resetActive}><Link to="#" onClick={e=>alert('해당 서비스는 오픈 준비 중입니다.')}>회원 통계</Link></li>
                            </Dropdown>
                        }
                    </div>

                    <div>
                        <li onClick={onClick} data-dropdown="message">
                            <span>발송 <FontAwesomeIcon icon={isActive['message'] ? faCaretUp : faCaretDown}/> </span>
                        </li>
                        {isActive['message'] && 
                            <Dropdown>
                                <li onClick={resetActive}><Link to="send_message">문자 발송</Link></li>
                                <li onClick={resetActive}><Link to="send_email">이메일 발송</Link></li>
                            </Dropdown>
                        }
                    </div>
                    <div>
                        <li onClick={resetActive}><Link to="/notice">공지사항</Link></li>
                    </div>
                </ul>
                <Logout/>
            </div>
            <MobileButtonTemplate onClick={clickMobileButton}>
                <DrawerMenu clicked={mobileMenuActive}/>
            </MobileButtonTemplate>
        </NavigationTemplate>
        </>
    );
}

export default Navigation;