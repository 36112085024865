import React, { useState } from "react";
import { axiosPost } from "../../apis/axios";
import { addComma } from "../../apis/common";
import Button from "../../components/Button";
import LabelContents from "../../components/payment/LabelContents";

const P2pExpired = ({data}) => {
    const [more, setMore] = useState(false);

    const p2pExpiredCancel = () => {
        let p2pCancleUrl = `asterpay/p2p/setDepositRejecting`
        axiosPost(p2pCancleUrl,{
            'paymentRequestNo' : data.paymentRequestNo
        })
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.reload()
            }
        })
        .catch(error => console.error(error));
    }

    return (
        <>
            <ul>
                <li><LabelContents title="결제 상태"><span>결제중</span></LabelContents></li>
                <li><LabelContents title="회원명"><span>{data.customerName}</span></LabelContents></li>
                <li><LabelContents title="위치"><span>{data.orderName}</span></LabelContents></li>
                <li><LabelContents title="주문 시각"><span>{data.insertedTs}</span></LabelContents></li>

                {/* 접기/더보기 버튼 만들기 */}
                <div><button onClick={e=>setMore(!more)}>[ more ]</button></div>
                {
                    more &&
                        <>
                        <li><LabelContents title="회원 휴대폰"><span>{data.mobile}</span></LabelContents></li>
                        <li><LabelContents title="회원 이메일"><span>{data.email}</span></LabelContents></li>
                        <li><LabelContents title="결제 번호"><span>{data.paymentRequestNo}</span></LabelContents></li>
                        <li><LabelContents title="원화 가격"><span>{addComma(data.amount)} 원</span></LabelContents></li>
                        <li><LabelContents title="주문 번호"><span>{data.orderId}</span></LabelContents></li>
                        </>
                }
            </ul>
            <Button width="100%" onClick={p2pExpiredCancel}>결제 취소 처리</Button>
        </>
    )
}
export default P2pExpired;