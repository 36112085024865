import React, { useLayoutEffect, useState } from "react";
import queryString from 'query-string';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { axiosGet, axiosPost } from "../../apis/axios";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Metarex
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}




function AlignItemsList() {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Brunch this weekend?"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Ali Connors
              </Typography>
              {" — I'll be in your neighborhood doing errands this…"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Summer BBQ"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                to Scott, Alex, Jennifer
              </Typography>
              {" — Wish I could come, but I'm out of town this…"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Oui Oui"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Sandra Adams
              </Typography>
              {' — Do you have Paris recommendations? Have you ever…'}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}



const theme = createTheme();

const SoldoutItemList = () => {

    const parsed = queryString.parse(window.location.search);
    const url = parsed.type;


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const json =
        {
          latitude: data.get('latitude'),
          longitude: data.get('longitude'),
          address: data.get('address'),
          alias: data.get('alias'),
          'youtube-url': data.get('youtube-url'),
        }

    console.log(json);

    axiosPost('api/item-management/add-bora-camera', json)
    .then(response => {
        if(!(JSON.parse(response.data.result))) {
            alert(response.data.message);
            return;
        }
    }).catch(e => {
        console.log(`sign in error : ${e}`);
    });

  };



    const addBoraCamera = () => {
        let type;
        let boardNo;
        if(!isNull(detail.boardNoticeNo)){
            type = 'boardNoticeNo';
            boardNo = detail.boardNoticeNo;
        } else {
            type = 'boardMetarexFaqNo';
            boardNo = detail.boardMetarexFaqNo;
        }
        // const boardNo = detail.boardNoticeNo ? detail.boardNoticeNo : detail.boardMetarexFaqNo;

        axiosPost(`${url}/update`, {
            'markupStr': text,
            'subjectKr': title,
            [type]: boardNo
        })
            .then(response => {
                alert(response.data.message);
                if(JSON.parse(response.data.result)){
                    navigate(`/${url}`);
                    return;
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            팔린 매물 보기
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="user-name"
                  name="user-name"
                  required
                  fullWidth
                  id="user-name"
                  label="이름"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="user-no"
                  label="회원 번호"
                  name="user-no"
                  autoComplete="회원 번호"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              조회
            </Button>
          </Box>
        </Box>
        <AlignItemsList>
        </AlignItemsList>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

export default SoldoutItemList;
