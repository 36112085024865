import React , {useEffect, useState} from 'react';
import RecomInfoList from '../recomInfoList/RecomInfoList';
import './RecomInfoWindow.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight ,  faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { numberToKorean } from '../../../apis/number';



const RecomInfoWindow = ({
    rendData ,listClickAddress , address , kakaoSearchAddress , setListClickAddress , keyword , setListClickRoadAddress , listClickRoadAddress ,  setRegistModal , setKakaoModalToggle , page , setKakaoSearchAddress , setSearchType , searchType
}) => {
    const [infoToggle, setInfoToggle] = useState(false)

    const recomModal = (e) =>{
        setRegistModal(true)
        setKakaoModalToggle(false)
    }
    const recomBtn = () => {
        setInfoToggle(!infoToggle)
    }

    useEffect(() => {
        // 검색목록이 배열일때
        if(Array.isArray(page?.data) === true){
            // 목록의 첫번째 값을 state에 담기.
            setKakaoSearchAddress(page?.data[0])
        }else {
            // 검색목록이 배열이 아닌 경우기에 직접 값을 담음.
            setKakaoSearchAddress(page?.data)
        }
    } , [page , searchType , keyword])


    return (
        <section className='infoWindowContainer'>
            <div className='leftInfo'>
                <ul>
                    <li>
                        <span>도로명 :</span>
                        <span>
                            {
                                // 맵클릭하거나 검색값이 있을때
                                searchType !== undefined ? 
                                (
                                    // 맵 클릭이 아닐때
                                    searchType !== 'mapClick' ?
                                        (
                                            // 검색어가 있고 목록 클릭하지 않았을때 검색목록 첫번째 주소값을 도로명에 표시
                                            (keyword !== "" && !listClickRoadAddress) ? (kakaoSearchAddress && kakaoSearchAddress?.road_address_name) 
                                            : 
                                            // 검색목록 클릭시 해당 주소값을 표시(listClickRoadAddress 이건 RecomInfoList에서 설정)
                                            (listClickRoadAddress && listClickRoadAddress !== "") && listClickRoadAddress
                                        ) 
                                        :
                                        // map클릭했을때는 해당 주소값 나타나게
                                        address.road_address?.address_name
                                )
                                :
                                // 맵클릭하지 않거나 검색값이 없을때
                                " -"
                            }
                        </span>
                    </li>
                    <li>
                        <span>지번 : </span>
                        <span>
                            {
                                searchType !== undefined ? 
                                (searchType !== 'mapClick' ?
                                    /* 위와 동일한 로직 */
                                    (
                                        (keyword !== "" && !listClickAddress && address === "") ? (kakaoSearchAddress && kakaoSearchAddress?.address_name) 
                                        : 
                                        (listClickAddress && listClickAddress !== "") && listClickAddress
                                    ) 
                                    :
                                    address.address?.address_name)
                                    : " -"
                            }
                        </span>
                    </li>
                    <li>
                        <span>토지면적 : {rendData?.parcel_info ? Math.round(rendData?.parcel_info?.area / 3.3) + "평" : " -" }</span>
                    </li>
                    <li>
                        <span>건축나이 : {rendData?.parcel_info ? rendData?.parcel_info?.age + "년" : " -" }</span>
                    </li>
                    <li>
                        <span>층수 : {rendData?.parcel_info?.floor ? rendData?.parcel_info?.floor + "층 " : " -" }</span>
                    </li>
                    <li>
                        <span>자료출처 : 랜드북</span>
                    </li>
                    <li>
                        <span>토지 가격 : {rendData?.estimated_price?.estimated_lot_price ? numberToKorean(rendData?.estimated_price?.estimated_lot_price) + "원" : " -"}</span>
                    </li>
                    <li>
                        <span>메타렉스 가격 : {rendData?.estimated_price?.estimated_lot_price ?  numberToKorean((rendData?.estimated_price?.estimated_lot_price) * 0.00003) + "원" : " -"}</span>
                    </li>
                </ul>
                <h2>
                </h2>
                <button onClick={recomModal} className="infoSendBtn">등록</button>
            </div>
            <div className={(kakaoSearchAddress !== undefined && searchType === "search") ? 'rightInfo' : 'rightInfoNone'}>
                {/* 버튼 누르기 전 검색 하고 난 후 */}
                {(infoToggle === false && (kakaoSearchAddress !== undefined)) === true ?
                    <>
                        <ul>
                            {/* 배열인지 확인후 배열일때만 map처리, 이렇게 한 이유는 상세한 주소값(1339-1)처럼 했을때 받아오는 값이 하나이므로 그때 map돌리면 버그, 하나일때는 하나만 출력되게*/}
                            {  
                                Array.isArray(page.data) === true ?
                                    page && page.data?.map((data,idx) => 
                                        <RecomInfoList data={data} key={idx} setListClickAddress={setListClickAddress} setListClickRoadAddress={setListClickRoadAddress}  setSearchType={setSearchType} searchType={searchType}></RecomInfoList>)
                                        :
                                        <RecomInfoList data={page.data}setListClickAddress={setListClickAddress} setListClickRoadAddress={setListClickRoadAddress} setSearchType={setSearchType} searchType={searchType}></RecomInfoList>
                            }
                        </ul>
                        <button type="submit" className="historyLeftBtn" onClick={recomBtn}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </button> 
                    </>
                    :
                    (infoToggle === true && (kakaoSearchAddress !== undefined)) === true &&
                    <button type="submit" className="historyRightBtn" onClick={recomBtn}>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                } 
            </div>
        </section>
    );
};

export default RecomInfoWindow;