import React from 'react';
import RegistrationForsale from './RegistrationForsale'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import './Registration.css'

const Registration = ({setRetouch , setKakaoModalToggle}) => {
    return (
        <section className='registContainer'>
            <h2>등록된 상품</h2>
            <div>
                <button className='registLeft'>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </button>
                <ul>
                    {/* 이부분 주소 면적 매입가는, 맵 클릭했을때의 주소값이 아닌 등록된 상품내의 정보니까 내가 따로 건들수가 없다.*/}
                    <RegistrationForsale setRetouch={setRetouch} setKakaoModalToggle={setKakaoModalToggle} ></RegistrationForsale>
                </ul>
                <button className='registRight'>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </button>
            </div>
        </section>
    );
};

export default Registration;