import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { axiosGet, axiosPost } from '../../../apis/axios';
import { addComma } from "../../../apis/common";
import SendBoard from '../../sendBoard/SendBoard';
import './ItemPointPaymentAndDeduction.css'




const ItemPointPaymentAndDeduction = () => {

    const inputElPointAmountToBePaid = useRef(null);

    /**
     * 좌표로 법정동 상세 주소 정보를 요청합니다
     */
    function getUserPointAmount(userNo) {
        let requestHoldingPoint = `api/pay-management/giving-or-deducting-point/point?userNo=${userNo}`;
            axiosGet(requestHoldingPoint)
            .then(res => setPointAmount(addComma(res.data.data)))
            .catch(error => console.error(error));
    }

    // INPUT값 넣기
    const [mesTitle,            setMesTitle]            = useState("");
    const [mesText,             setMesText]             = useState("");
    const [mesPhone,            setMesPhone]            = useState("");
    const [mesName ,            setMesName]             = useState("");
    const [comment ,            setComment]             = useState("");
    const [pointAmount ,        setPointAmount]         = useState("");
    const [pointAmountToBePaid, setPointAmountToBePaid] = useState("");

    // modal toggle
    const [messageBoard , setMessageBoard] = useState(false)
    // message user 
    const [messageUser , setMessageUser] = useState("")

    const [ userRemoveText, setUserRemoveText ] = useState(false)

    // 회원탈퇴 메시지 불러오기 기능
    const userRemove = (e) => {
        e.preventDefault()
        setUserRemoveText(true)
        if(mesText !== ""){
            setMesText(mesText + userRemoveareaText())
        }else if(mesText === ""){
            setMesText(userRemoveareaText())
        }
    }
    // 회원탈퇴 메시지 누를때
    const userRemoveareaText = () => {
        return "데이터 삭제 및 탈퇴처리가 모두 완료되었습니다. 이용해주셔서 감사합니다."
    }

    {/*회원 번호가 변경 될 때, 해당 사용자의 보유 point를 조회한다.*/}
    useEffect(() => {
        getUserPointAmount(messageUser?.userNo);
    }, [messageUser?.userNo]);

    {/*지급할 Point 수량을 항상 변수와 동기화 해준다.*/}
    useEffect(() => {
         pointAmountToBePaid
    }, [messageUser?.userNo]);

    const messageToggle = (e) => {
        e.preventDefault();
        setMessageBoard(true)
    }

    const messageSend = (e) => {
        {/* 버튼 누를때 새로고침 제거*/}
        e.preventDefault()

        // 회원선택에서 값을 받아오면 담고, 아니면 mesPhone에 담긴 input입력값을 담는다.
        let phone = messageUser?.mobile ? messageUser?.mobile : mesPhone
        // 회원선택에서 값을 받아오면 담고, 아니면 mesName에 담긴 input입력값을 담는다.
        let name = messageUser?.nameKr ? messageUser?.nameKr : mesName
        
        if( messageUser === undefined || messageUser?.userNo === undefined || messageUser?.userNo === ""){
            alert("회원을 선택하세요.");
        } else if( pointAmountToBePaid === "" ) {
            console.log('%o', messageUser?.userNo);
            alert("지급할 포인트를 입력하세요.");
        }else {   
            if(window.confirm(
                `${addComma(pointAmountToBePaid)} 포인트를 ${name}(${phone})고객에게 지급하시겠습니까?`))
            {
                axiosPost(`api/pay-management/giving-or-deducting-point/point`, {
                    'userNo' : messageUser?.userNo,
                    'pointAmountToBePaid' : pointAmountToBePaid,
                    'comment' : comment
                })
                .then(response => {
                    if(response){
                        alert(response.data?.message);
                        setPointAmountToBePaid("");
                        inputElPointAmountToBePaid.current.value = '';
                        getUserPointAmount(messageUser?.userNo);
                    }
                })
                .catch(error => console.error(error));
            }   
        }
    }

    return (
        <section className='messageContainer'>
            <form action="">
                <ul>
                    <li>
                        <h3>Point 지급 및 차감</h3>
                    </li>                    
                    <li>
                        <button onClick={messageToggle}>회원 선택</button>
                    </li>
                    <li>
                        <label>회원번호</label>
                        <input name="회원번호" type="text" autoComplete="off" defaultValue={messageUser?.userNo} disabled/> 
                    </li>
                    <li>
                        <label>이름</label>
                        <input name="이름" type="text" onChange={(e) => setMesName(e.target.value)} defaultValue={messageUser?.nameKr} autoComplete="off" disabled/>
                    </li>                    
                    <li>
                        <label>휴대폰 번호</label>
                        <input name="user-mobile" type="text" onChange={(e) =>setMesPhone(e.target.value)} defaultValue={messageUser?.mobile}  autoComplete="off" disabled/>
                    </li>
                    <li>
                        <label>보유포인트</label>
                        <input name="point-amount" type="text" defaultValue={pointAmount} autoComplete="off" disabled/> 
                    </li>
                    <li>
                        <label>지급할 Point 수량. -10은 10 point 차감</label>
                        <input name="point-amount-to-be-paid" type="number" onChange={(e) =>setPointAmountToBePaid(e.target.value)} ref={inputElPointAmountToBePaid} autoComplete="off"/>
                    </li>
                    <li>
                        <label>포인트 지급 사유(선택항목)</label>
                        <textarea name="comment" type="text" onChange={(e) => setComment(e.target.value)} 
                        value = {comment}>
                        </textarea>
                    </li>
                    <li>
                        <button onClick={messageSend}>Point 지급하기</button>
                    </li>
                    <li>
                        <hr />
                    </li>
                    <li>
                        <button onClick={userRemove}>회원탈퇴 메시지 불러오기</button>
                    </li>
                </ul>
            </form>
            {
                messageBoard === true &&
                <SendBoard setMessageBoard={setMessageBoard} messageBoard={messageBoard} setMessageUser={setMessageUser} isClipboardEnabled={false}></SendBoard>
            }
        </section>
    );
};

export default ItemPointPaymentAndDeduction;