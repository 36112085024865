import React, { useLayoutEffect, useState } from "react";
import SupportTemplate from "../../../components/SupportTemplate";
import queryString from 'query-string';
import { isNull } from "../../../apis/common";
import { axiosGet, axiosPost } from "../../../apis/axios";
import RichTextEditor from "../../../components/textEditor/RichTextEditor";
import styled from "styled-components";
import Button from "../../../components/Button";
import { Link, useNavigate } from "react-router-dom";

const QuestionTemplate = styled.div`
    width: 100%;
`;

const QuestionsDiv = styled.div`
    width: 100%;
    max-width: 777px;
    >.title{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 5px;
    }
    >p{
        width: 100%;
        margin-bottom: 5px;
        >span{
            display: inline-block;
            width: 100px;
            position: relative;

            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            &::after{
                position: absolute;
                content: ":";
                right: 0;
            }
        }
    }
`;

const QuestionContent = styled.div`
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 50px;
    img{
        width: 100%;
    }
`;

const WriteQuestionTemplate = styled.div`
    display: block;
    width: 100%;
    max-width: 777px;
    margin-top: 20px;
    margin-bottom: 70px;
    >.title{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 5px;
    }
`;

const ButtonTemplate = styled.div`
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ReplyQuestions = () => {
    const [question, setQuestion] = useState();
    const [replyQuestion, setreplyQuestion] = useState();
    const parsed = queryString.parse(window.location.search);
    const questionNo = Number(parsed.idx);
    const navigate = useNavigate();

    useLayoutEffect(()=>{
        if(!isNull(questionNo)){
            getQuestionByBoardNo(Number(questionNo));
        }
    },[]);

    const getQuestionByBoardNo = (contentsNo) => {
        axiosGet(`contactUs/detail/select?idx=${contentsNo}`)
        .then(response => {
            if(JSON.parse(response.data.result)){
                setQuestion(response.data.data.boardContactUs);
                const reply = response.data.data.boardContactUsReply;
                if(!isNull(reply)){
                    setreplyQuestion(reply.body);
                }
                return;
            }
            alert(response.data.message);
        })
        .catch(error => {
            console.error(error);
        })
    }

    const insertData = () => {
        if(isNull(replyQuestion) || replyQuestion === '<p><br></p>' || replyQuestion === '<p></p>') {
            alert('내용을 입력해주세요.');
            return;
        }
        // questionNo

        if (window.confirm(`답변 메일을 보내시겠습니까? 이메일로 발송되므로, 수정이 불가합니다.`)) {
            sendToEmail({
                'boardContactUsNo': questionNo,
                'markupStr': replyQuestion
            });
        } 
    }

    const sendToEmail = (json) => {
        axiosPost('contactUsReply/insert', json)
            .then(response => {
                alert(response.data.message);
                navigate('/questions');
            })
            .catch(error => {
                console.error(error);
            });
    }


    return (
        <SupportTemplate>
            {
                question &&
                <>
                    <h1>{question.subject}</h1>

                    <QuestionTemplate>
                        <QuestionsDiv>
                            <p><span>문의시각</span> {question.insertedTs}</p> 
                            <p><span>고객 이메일</span> {question.email}</p>
                            <p className="title">문의 내용</p>
                            <QuestionContent dangerouslySetInnerHTML={{__html: question.body}}/>
                        </QuestionsDiv>
                    </QuestionTemplate>
                    
                    <div>
                        <WriteQuestionTemplate>
                            <p className="title">답변 작성</p>
                            <RichTextEditor item={replyQuestion} setText={setreplyQuestion} />
                        </WriteQuestionTemplate>
                        <ButtonTemplate>
                            <Button width='155px' onClick={insertData}>답변 이메일 보내기</Button>
                            <Link to='/questions'><Button width='155px'>목록</Button></Link>
                        </ButtonTemplate>
                    </div>
                </>
            }

        </SupportTemplate>
    );
}

export default ReplyQuestions;