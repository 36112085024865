import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { axiosGet } from "../../../apis/axios";
import PageTemplate from "../../../components/PageTemplate";
import ItemCard from "../../../components/payment/ItemCard";
import P2pApproval from "../../../contents/payments/P2pApproval";
import { MOCK_DATA_FOR_P2P_REQUEST } from "../../../mockData";

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    >p{
        display: inline;
    }
`;

const ItemPointBuyApproval = () => {
    const [requestList, setRequestList] = useState([]);
    useEffect(() => {
        let p2pApprovalUrl = `asterpay/history/point/unconfirmed-items?pageNum=1&numPerPage=10`;
        axiosGet(p2pApprovalUrl)
        .then(response => {
            if(JSON.parse(response.data.result)){                
                setRequestList(response?.data?.data);
            } else {
                alert(response.data.message);
                if( response.data?.href ) {                    
                    removeCookieAPI();
                    document.location.href = response.data?.href;
                }
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
    },[]);

    return (
        <PageTemplate>
            <TitleDiv>                
                <p>Point 구매 승인처리</p>
                <span>사용자가 코인으로 point를 구매할 때, '입금 완료 처리' 또는 '결체 취소 처리'를 진행해주세요.</span>
            </TitleDiv>
            {
                requestList?.data?.length === 0 ?
                <h2>
                    목록이 없습니다.
                </h2>
                : 
                requestList?.data?.map((el,index) => <ItemCard key={index}><P2pApproval data={el}/></ItemCard>)
            }
        </PageTemplate>
    );
}

export default ItemPointBuyApproval;