import React, { Children } from "react";
import styled from "styled-components";

const StyledScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .8);
    width: 100vw;
    height: 100vh;
`;

const Screen = ({Children}) => {
    return (
        <StyledScreen>
            {Children}
        </StyledScreen>
    );    
}

export default Screen;