import React, { useLayoutEffect, useState } from "react";
import { axiosGet } from "../../../apis/axios";
import Board from "../../../components/board/Board";
import SupportTemplate from "../../../components/SupportTemplate";

const Questions = () => {
    const PAGE_PER_NUM = 5;
    const [questions, setQuestions] = useState([]);
    const [pageNum, setPageNum] = useState(1);

    useLayoutEffect(() => {
        getQuestions();
    }, []);

    const getQuestions = () => {
        axiosGet('contactUs/select')
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setQuestions(response.data.data);
                    return;
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <SupportTemplate>
            <h1>문의내역</h1>
            {questions.length !== 0 &&
                <div>
                    <Board dataList={questions} pageNum={pageNum} setPageNum={setPageNum} totalCount={questions.length} PAGE_PER_NUM={PAGE_PER_NUM} url="/questions"/>
                </div>
            }
        </SupportTemplate>
    );
}

export default Questions;