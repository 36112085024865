import { useState , useEffect} from "react";
import { axiosGet, axiosPost } from "../../apis/axios";
import { addComma } from "../../apis/common";
import Button from "../../components/Button";
import LabelContents from "../../components/payment/LabelContents";
import Screen from "../../components/Screen";


const ItemApproval = ({data}) => {
    const [more, setMore] = useState(false);
    const [onModal, setOnModal] = useState(false);
    // 거절 목록
    const [refuseList , setRefuseList] = useState()
    // 거절 사유 post 보내기
    const [refuseReason, setRefuseReason] = useState()
    
    // 자율구매 거절 목록
    useEffect(()=>{
        let requestApprovalRefuse = `api/payment/reject-type/request-item`
        axiosGet(requestApprovalRefuse)
        .then(res => setRefuseList(res.data))
        .catch(error => console.error(error));
    },[]);

    const approvalSend = () => {
        let approvalSendUrl = `api/realestate/approvalRequestItem`
        axiosPost(approvalSendUrl,{
            'realestateRequestedNo' : data.realestateRequestedNo
        })
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.reload()
            }
        })
        .catch(error => console.error(error));
    }
    
    const approvalCancel = () => {
        let approvalSendUrl = `api/realestate/rejectRequestItem`
        axiosPost(approvalSendUrl,{
            'adminRejectTypeNo' : refuseReason === undefined ? 1 : Number(refuseReason),
            'realestateRequestedNo' : data.realestateRequestedNo
        })
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.reload()
            }
        })
        .catch(error => console.error(error));
    }

    return (
        <>
            {onModal && <Screen></Screen>}
            <ul>
                <li><LabelContents title="승인상태"><span>미승인</span></LabelContents></li>
                <li onClick={e => setOnModal(!onModal)}><LabelContents title="회원명"><span>{data.nameKr}</span></LabelContents></li>
                <li><LabelContents title="위치"><span>{data.addressFound}</span></LabelContents></li>
                <li><LabelContents title="가격"><span>{`${addComma(data.priceMetarex)} 원`}</span></LabelContents></li>
                <li><LabelContents title="요청 시각"><span>{data.insertedTs}</span></LabelContents></li>

                {/* 접기/더보기 버튼 만들기 */}
                <div><button onClick={e=>setMore(!more)}>[ more ]</button></div>
                {
                    more &&
                        <>
                        <li><LabelContents title="회원 휴대폰"><span>{data.mobile}</span></LabelContents></li>
                        <li><LabelContents title="회원 이메일"><span>{data.email}</span></LabelContents></li>
                        <li><LabelContents title="요청 번호"><span>{data.realestateRequestedNo}</span></LabelContents></li>
                        </>
                }
            </ul>
            <Button onClick={approvalSend} width="100%">매물 승인 처리</Button>
            <LabelContents title="거절 사유">
                <select width="100%" onChange={(e) => setRefuseReason(e.target.value)}>
                    {
                        refuseList?.result === 'true' &&
                        refuseList?.data.map(( data, idx )=>
                        <option value={data.adminRejectTypeNo} key={idx}>{data.adminRejectTypeName}</option>)
                    }
                </select>
            </LabelContents>
            <Button onClick={approvalCancel} width="100%">매물 거절 처리</Button>
        </>
    )
}

export default ItemApproval;