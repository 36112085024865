import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { axiosPost } from "../../apis/axios";

const StyledLogoutDiv = styled.div`
    height: 100%;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
        cursor: pointer;
        width: 110px;
        height: 100%;
        color: #fff;
        font-family: NanumSquare;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media only screen and (max-width: 768px) {
        display: flex;
        width:100%;
        height:60px;
    }
`;


const Logout = () => {
    const SignOut = (e) => {
        if(window.confirm("로그아웃 하시겠습니까?")){
            let logOutUrl = `api/user/removeSessionId`
            axiosPost(logOutUrl)
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    // console.log(response.data);                
                }
            }).catch(e => {
                console.log(`error message: ${e}`);
            });
            window.location.href = '/';
            // localStorage.removeItem('adminSessionId');
            // window.location.href = '/';
            // removeCookieAPI();
            // setIsSession(false);
            return;
        }
        e.preventDefault();
    }

    return (
        <StyledLogoutDiv>
            <Link to="/" onClick={SignOut}>로그아웃</Link>
        </StyledLogoutDiv>
    );
}

export default Logout;