import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { faPenSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { act } from "@testing-library/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { axiosPost } from "../apis/axios";
import Button from "./Button";

const CardTemplate = styled.div`
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    align-items: center;
    margin: 20px;
    >.info{
        width: 100%;
        padding: 5px 10px;
    }
    .nav{
        width: 100%;
        padding: 5px;
        height: 30px;
        background-color: #05141F;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        >svg{
            height: 20px;
            width: 20px;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            &:first-of-type{
                margin-right: 10px;
            }
        }
    }
`;

const NavTemplate = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
`

const ImageTemplate = styled.div`
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    >a{
        width: 100%;
        >img{
        width: 100%;
        }
    }
`;

const TextareaTemplate = styled.textarea`
    width: 100%;
    height: auto;
    padding: 10px;
    resize: none;
    border: 1px solid #D9DDE2;
    box-sizing: border-box;
    &:disabled {
        border: 1px solid #fff;
        background: #fff;
    }
`;

const Label = styled.label`
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 14px;
`;

const InformationInput = styled.input`
    width: 100%;
    height: 48px;
    border: 1px solid #D9DDE2;
    border-radius: 2px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px 10px;
    margin: 5px 0px;
    box-sizing: border-box;
    &:disabled {
        border: 1px solid #fff;
        background: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const VideoCard = ({item}) => {
    const [isEdit, setIsEdit] = useState(false);

    const [youtubeCode, setYoutubeCode] = useState(item.url);
    const [titleKr, setTitleKr] = useState(item.sloganKr);
    const [titleEn, setTitleEn] = useState(item.sloganEn);
    const [titleCn, setTitleCn] = useState(item.sloganCn); 
    const [details, setDetails] = useState(item.memo);


    const openPage = (e) => {
        e.preventDefault();
        const url = `https://youtu.be/${item.url}`;
        window.open(url, '_blank');
    }

    const deleteData = () => {
        if(confirm('이 동영상 정보를 삭제하시겠습니까?')) {
            const json = {
                'mainVideoIndex': item.mainVideoIndex
            }
            axiosPost('mainVideo/delete', json)
                .then(response => {
                    alert(response.data.message);
                    if(JSON.parse(response.data.result)){
                        window.location.href = '/main_video';
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }

    const updateData = () => {
        if(confirm('이 동영상 정보를 수정하시겠습니까?')) {
            const json = {
                'mainVideoIndex': item.mainVideoIndex,
                youtubeCode,
                titleKr,
                titleEn,
                titleCn,
                details
            }
            axiosPost('mainVideo/update', json)
                .then(response => {
                    alert(response.data.message);
                    if(JSON.parse(response.data.result)){
                        setIsEdit(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }

    const changeActivate = () => {
        console.log(item.activate);
        if(confirm(item.activate ? "동영상 사용을 중지하시겠습니까?" : "이 동영상을 사용하시겠습니까?")) {
            const json = {
                'mainVideoIndex': item.mainVideoIndex,
                'activate' : !(item.activate)
            }
    
            axiosPost('mainVideo/update/display', json)
            .then(response => {
                alert(response.data.message);
                if(JSON.parse(response.data.result)){
                    window.location.href = '/main_video';
                }
            })
            .catch(error => {
                console.error(error);
            })
        }
    } 

    return (
        <CardTemplate>
                <div className="nav">
                    { !(item.activate) &&
                        <>
                            <FontAwesomeIcon icon={isEdit ? faWindowClose : faPenSquare} onClick={e => setIsEdit(!isEdit) }/>
                            <FontAwesomeIcon icon={faTrash} onClick={deleteData}/>
                        </> 
                    }
                </div>
                <ImageTemplate>
                    <Link to="#" rel="noopener noreferrer" onClick={openPage} title={details}>
                        <img src={`https://i.ytimg.com/vi/${youtubeCode}/mqdefault.jpg`}/>
                    </Link>
                </ImageTemplate>
                <div className="info">
                    <Label htmlFor="youtube_code">youtube 코드(required)</Label>
                    <InformationInput width="100%" id="youtube_code" value={youtubeCode} onChange={e => setYoutubeCode(e.target.value)} disabled={!isEdit}/>
                </div>
                <div className="info">
                    <Label htmlFor="sloganKr">메인화면 타이틀 한국어(required)</Label>
                    <InformationInput width="100%" id="sloganKr" value={titleKr} onChange={e => setTitleKr(e.target.value)} disabled={!isEdit}/>
                </div>
                <div className="info">
                    <Label htmlFor="sloganKr">메인화면 타이틀 영어(required)</Label>
                    <InformationInput width="100%" id="sloganKr" value={titleEn} onChange={e => setTitleEn(e.target.value)} disabled={!isEdit}/>
                </div>
                <div className="info">
                    <Label htmlFor="sloganKr">메인화면 타이틀 중국어(required)</Label>
                    <InformationInput width="100%" id="sloganKr" value={titleCn} onChange={e => setTitleCn(e.target.value)} disabled={!isEdit}/>
                </div>
                <div className="info">
                    <Label htmlFor="details">설명</Label>
                    <TextareaTemplate id="details" disabled={!isEdit} value={details} onChange={e => setDetails(e.target.value)}/>    
                </div>
                <NavTemplate>
                    {item.activate 
                        ? <Button width="100px" style={{backgroundColor:"#3D85F0"}} onClick={changeActivate}>사용중</Button>
                        : isEdit 
                            ? <>
                                <Button width="100px" onClick={updateData} style={{marginRight:"10px"}}>수정완료</Button>
                                <Button width="100px" onClick={e => setIsEdit(false)}>취소</Button>
                              </>
                            : <>
                                <Button width="100px" onClick={changeActivate}>사용하기</Button>
                              </>
                    }
                </NavTemplate>
        </CardTemplate>
    );
}

export default VideoCard;