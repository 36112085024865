import { axiosPost } from "./axios";

export const requstSignIn = async (json) => {
    axiosPost('signin/email/set', json)
    .then(response => {
        if(!(JSON.parse(response.data.result))) {
            alert(response.data.message);
            return;
        }
        localStorage.setItem('adminSessionId', response.data.adminSessionId);
        localStorage.setItem('adminName', response.data.name);
        localStorage.setItem('adminMobile', response.data.mobile);
        localStorage.setItem('adminEmail', response.data.email);

        //alert('로그인에 성공하였습니다.');
        // 결제 링크 로그인시 뒤로가기 로직
        const referrer = String(document.referrer);
        if( referrer.indexOf(`/sbr?u=`) > -1 ) {
            // history.back();
        } else {
            window.location.replace("/main");
        }
    }).catch(e => {
        console.log(`sign in error : ${e}`);
    });
}