import React from "react";
import styled from "styled-components";

const PageTempalteDiv = styled.div`
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >h2{
        display: block;
        width : 100%;
        height : 400px;
        text-align : center;
        line-height : 400px;
        font-size : 30px;
    }
`;

const PageTemplate = ({children}) => {
    return (
        <PageTempalteDiv>
            {children}
        </PageTempalteDiv>
    );
}

export default PageTemplate;