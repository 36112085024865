import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { axiosGet } from '../../apis/axios';
import './SendBoard.css'

const SendBoard = ({setMessageBoard , messageBoard, setEmailBoard , setMessageUser , setEmailUser, isClipboardEnabled}) => {

    // 검색값을 받아 해당 글자를 마커 처리
    let highlightedText = (text, query) => {
        let highlight = String(text)
        if (query !== '' && highlight.includes(query)) {
            const parts = highlight.split(new RegExp(`(${query})`, 'gi'));
            return (
                <>
                    {parts.map((part, index) =>
                        part.toLowerCase() === query.toLowerCase() ? (<mark key={index}>{part}</mark>) : (part),
                    )}
                </>
            );
        }
        return highlight;
    };

    const [userInput , setUserInput] = useState("");
    const [userData, setUserData] = useState();

    // 회원선택 목록 클릭
    const [listToggle, setListToggle ] = useState(false);
    // 회원관리 > 전체회원 게시판인지, 발송의 회원선택인지 path값을 토대로 true,false값을 줘서 css를 수정
    // 전체회원페이지는 false, 회원선택은 true값.
    const [sendBoardCss , setSendBoardCss] = useState(false);

    // Click 시, clipboard copy를 enable할지 정하는 variable.
    //const [isClipboardEnabled , setIsClipboardEnabled] = useState()
    
    const userBoardInput = (e) => {
        setUserInput(e.target.value);
    }
    // 회원선택 닫기 버튼
    const boardToggle = () => {
        if(window.confirm("팝업을 종료하시겠습니까?")){
            if( window.location.pathname === "/send_message" || window.location.pathname === "/item-point-payment-and-deduction" ){
                setMessageBoard(false);
            }else if(window.location.pathname === "/send_email"){
                setEmailBoard(false);
            }
        }
    }
    useEffect(()=>{
        if(listToggle === true ){
            if(window.location.pathname === "/send_message" || window.location.pathname === "/item-point-payment-and-deduction" ){
                setMessageBoard(false)

                // 회원 목록 클릭시 메시지에 전송
                setMessageUser(userListClick)
            }else if(window.location.pathname === "/send_email"){
                setEmailBoard(false)

                // 회원 목록 클릭시 이메일에 전송
                setEmailUser(userListClick)
            }
        }
    })

    // path값을 토대로 회원선택 페이지 일시 css container 수정 
    useEffect(()=>{
        if( messageBoard === false && window.location.pathname === "/send_board"){
            setSendBoardCss(false)
        }else if (messageBoard !== false && window.location.pathname !== "/send_board" ){
            setSendBoardCss(true)
        }
    })

    useEffect( ()=>{
        let userUrl = (userInput) => `iFrame/selectUserByUserNoNameKrEmailMobile?keyword=${encodeURI(userInput)}`
        // 검색값을 보낸다.
        let res = axiosGet(userUrl(userInput)) 
        .then(response => {
            setUserData(response);
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
    },[userInput , setUserData]);

    // 회원 게시판 클릭했을때
    let [userListClick, setUserListClick] = useState();
    
    const userClick = (data) => {
        setUserListClick(data);
        setListToggle(true);
    }

    const clipboard = (e) => {
        const value = e.target.textContent;
        navigator.clipboard.writeText(value)
            .then(() => alert('복사가 완료되었습니다.'))
            .catch(error => console.error(error));
    }

    return(
        <div className={sendBoardCss === true ? 'sendBoardContainer' : 'sendAllContainer'} >
            {
                sendBoardCss === true ?
                <button onClick={boardToggle}>닫기 X</button>
                :
                ""
            }
            <div>
                <button onClick={() => window.location.reload()}>새로고침</button>
            </div>
            <h2>{sendBoardCss === true ? "회원선택" : "전체 회원"}</h2>
            <section>
                <label htmlFor="keyword">검색창</label>
                <input type="text" id="keyword" onChange={userBoardInput}/>
            </section>
            <table className='sendTable'>
                <thead className='sendThead'>
                    <tr>
                        <th>회원번호</th>
                        <th>이름</th>
                        <th>이메일</th>
                        <th>휴대폰</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        userData?.data.map((data , idx) => {
                            return(
                                <tr key = {idx} onClick={() => userClick(data)}>
                                    <td onClick={isClipboardEnabled ? clipboard : ''}>{highlightedText(data?.userNo , userInput)}</td>
                                    <td onClick={isClipboardEnabled ? clipboard : ''}>{highlightedText(data?.nameKr , userInput)}</td>
                                    <td onClick={isClipboardEnabled ? clipboard : ''}>{highlightedText(data?.email , userInput)}</td>
                                    <td onClick={isClipboardEnabled ? clipboard : ''}>{highlightedText(data?.mobile , userInput)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )

}

export default SendBoard;