import React, { useState } from "react";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import styled from "styled-components";
import { axiosPost } from "../../apis/axios";
import Button from "../../components/Button";
import SupportTemplate from '../../components/SupportTemplate';
import InputBox from "../../components/textEditor/InputBox";
import RichTextEditor from "../../components/textEditor/RichTextEditor";

const WriteNoticeTemplate = styled.div`
    display: block;
    width: 100%;
    max-width: 777px;
    margin-top: 50px;
    margin-bottom: 70px;
`;

const ButtonTemplate = styled.div`
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const WriteNotice = () => {
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const parsed = queryString.parse(window.location.search);
    const url = parsed.type;

    const insertData = () => {
        const checkTitle = title.replaceAll(' ', '');
        if(checkTitle === '') {
            alert('제목을 입력해주세요');
            return;
        }

        if(text === '' || text === '<p><br></p>' || text === '<p></p>') {
            alert('내용을 입력해주세요.');
            return;
        }

        if (window.confirm(`게시글을 등록하시겠습니까?`)) {
            axiosPost(`${url}/insert`, {
                'subjectKr': title,
                'markupStr': text
            })
            .then(response => {
                alert(response.data.message);
                if(JSON.parse(response.data.result)){
                    window.location.href=`/${url}`;
                }
            })
            .catch(error => {
                console.error(error);
            });
        } 
    }


    return (
        <SupportTemplate>
            <h1>{url === 'notice' ? "공지사항" : "FAQ"} 글쓰기</h1>
            <div>
                <WriteNoticeTemplate>
                    <InputBox type="text" placeholder="제목" item={title} setItem={setTitle} />
                    <RichTextEditor item={text} setText={setText} />
                </WriteNoticeTemplate>
                <ButtonTemplate>
                    <Link to={`/${url}`}><Button width="155px">취소</Button></Link>
                    <Button width="155px" onClick={insertData}>등록</Button>
                </ButtonTemplate>
            </div>
        </SupportTemplate>
    )
}
export default WriteNotice;