import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { axiosGet } from "../../apis/axios";
import PageTemplate from "../../components/PageTemplate";
import ItemCard from "../../components/payment/ItemCard";
import NewExpired from "../../contents/payments/NewExpired";
import { MOCK_DATA_FOR_P2P_EXPIRED } from "../../mockData";

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    >p{
        display: inline;
    }
`;

const ItemPaytmentExpired = () => {
    return (
        <PageTemplate>
            <TitleDiv>                
                <p>NEW 매물 결제 만료처리</p>
                <span>결제 시간 만료에 따른 '결체 취소 처리'를 진행해주세요.</span>
            </TitleDiv>
                <h2>
                    매물이 없습니다.
                </h2>
            {/* {expiredList.result === 'true'
                && expiredList?.data.map((el,index) => <ItemCard key={index}><NewExpired data={el}/></ItemCard>)
            } */}
        </PageTemplate>
    );
}

export default ItemPaytmentExpired;