import React, { useLayoutEffect, useState } from 'react';
import { axiosGet } from '../../apis/axios';
import Board from '../../components/board/Board';
import SupportTemplate from '../../components/SupportTemplate';

const Notice = () => {
    const PAGE_PER_NUM = 5;
    const [noticeList, setNoticeList] = useState([]);
    const [pageNum, setPageNum] = useState(1);

    useLayoutEffect(() => {
        noticeSelect();
    }, [pageNum]);

    const noticeSelect = async () => {
        axiosGet(`notice/select`)
            .then(response => {
                if(JSON.parse(Number(response.status) === 200)){
                    setNoticeList(response.data);
                } else {
                    alert(response.data.message);
                }
            }).catch(e => {
                console.log(`notice page error message: ${e}`);
            });
    }
    
    return (
        <SupportTemplate>
            <h1>공지사항</h1>
            {noticeList.length !== 0 &&
                <div>
                    <Board dataList={noticeList} pageNum={pageNum} setPageNum={setPageNum} totalCount={noticeList.length} PAGE_PER_NUM={PAGE_PER_NUM} url="/notice"/>
                </div>
            }
        </SupportTemplate>
    );
}

export default Notice;