import React, { useState } from "react";
import styled from "styled-components";
import { axiosPost } from "../apis/axios";
import { isNull } from "../apis/common";
import Button from "./Button";
import Input from "./Input";

const UploadVideosTemplate = styled.fieldset`
    width: 100%;
    max-width: 777px; 
    padding: 20px;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    >div{
        margin: 0px 0px 20px 0px;
    }
    label{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 14px;
        text-align: center;
    }
`;

const SubTitle = styled.legend`
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
`;


const UploadData = ({requestUrl, subTitle}) => {
    const [newTitle, setNewTitle] = useState('');
    const [newEnTitle, setNewEnTitle] = useState('');
    const [newCnTitle, setNewCnTitle] = useState('');
    const [newUri, setNewUri] = useState('');
    const [pressedDate, setPressedDate] = useState('');

    const resetContents = () => {
        setNewTitle('');
        setNewEnTitle('');
        setNewCnTitle('');
        setNewUri('');
        setPressedDate('');
    }

    const insertData = () => {
        if(isNull(newTitle) || newTitle === ''){
            alert('한글 제목을 입력해주세요.');
            return;
        }

        if(isNull(newUri) || newUri === ''){
            alert('url을 입력해주세요.');
            return;
        }

        if (window.confirm(`정보를 추가하시겠습니까?`)) {
            let json = {};
            if(requestUrl === 'videos'){
                if(isNull(newEnTitle) || newEnTitle === ''){
                    alert('영문 제목을 입력해주세요.');
                    return;
                }

                if(isNull(newCnTitle) || newCnTitle === ''){
                    alert('중문 제목을 입력해주세요.');
                    return;
                }

                json = {
                    'subjectKr': newTitle,
                    'subjectEn': newEnTitle,
                    'subjectCn': newCnTitle,
                    'uri': newUri
                }
            } else if(requestUrl === 'hotIssue'){
                if(isNull(pressedDate) || pressedDate === ''){
                    alert('날짜를 선택해주세요.');
                    return;
                }

                json = {
                    'subjectKr': newTitle,
                    pressedDate,
                    'uri': newUri
                }
            }
            axiosPost(`${requestUrl}/insert`, json)
                .then(response => {
                    alert(response.data.message);
                    if(JSON.parse(response.data.result)){
                        resetContents();
                        window.location.href = `/${requestUrl.split("/")[0]}`;
                        return;
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    return (    
    <UploadVideosTemplate>
        <SubTitle>{subTitle} 등록</SubTitle>
        <div>
            <label htmlFor="subjectKr">한글 제목 : </label>
            <Input id="subjectKr" type="text" value={newTitle} onChange={e => setNewTitle(e.target.value)} placeholder="한글 제목을 입력해주세요." width="100%" />
        </div>
        { subTitle === '자료실' ?
        <>
            <div>
                <label htmlFor="subjectEn">영어 제목 : </label>
                <Input id="subjectEn" type="text" value={newEnTitle} onChange={e => setNewEnTitle(e.target.value)} placeholder="영어 제목을 입력해주세요." width="100%" />
            </div>
            <div>
                <label htmlFor="subjectCn">중국어 제목 : </label>
                <Input id="subjectCn" type="text" value={newCnTitle} onChange={e => setNewCnTitle(e.target.value)} placeholder="중국어 제목을 입력해주세요." width="100%" />
            </div>
        </>
        : 
        <div>
            <label htmlFor="pressed">게재일 : </label>
            <Input id="pressed" type="date" value={pressedDate} onChange={e => setPressedDate(e.target.value)} placeholder="날짜를 입력해주세요." width="100%" />
        </div>  
        }
        <div>
            <label htmlFor="url">{subTitle === '자료실' ? "URL" : "LINK"} :</label>
            <Input id="url" type="text" value={newUri} onChange={e => setNewUri(e.target.value)} placeholder={`${subTitle === '자료실' ? "URL" : "LINK"} (을/를) 입력해주세요.`} width="100%"/>
        </div>
        <Button onClick={insertData} width="100%">등록</Button>
    </UploadVideosTemplate>
    );

}

export default UploadData;