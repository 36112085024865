import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import queryString from 'query-string';
import { axiosPost } from "../../apis/axios";
import Button from "../Button";
import BoardContent from "./BoardContent";
import BoardList from "./BoardList";
import Pagination from "./Pagination";
import { isNull } from "../../apis/common";

const BorderTemplate = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
`;

const StyledNoticeDetail = styled.div`
    padding: 40px 0px 200px 90px;
    margin-bottom: 60px;
    border-bottom: 1px solid #D9DDE2;
    width: 100%;
    >div {
        flex-direction: column;
        width: 100%;
    }
    >p { 
        width: 100%;
    }
    @media only screen and (max-width: 768px) {
        padding: 40px 20px 50px 20px;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.02em;
        text-align: left;

        div {
            margin-bottom: 50px;
        }
        margin-bottom: 20px;
    }
`;

const ButtonTemplate = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const BoardPointHistory = ({dataList, pageNum, setPageNum, totalCount, PAGE_PER_NUM, url}) => {
    const [openPage, setOpenPage] = useState(false);
    const [openPageContent, setOpenPageContent] = useState({});
    const [isQuestion, setIsQuestion] = useState(false);
    const navigate = useNavigate();
    const [firstRender, setFirstRender] = useState(true);

  
    console.log( 'thkim 20220811 %o', dataList);
    useEffect(()=>{
        let start = totalCount - (PAGE_PER_NUM * pageNum);
        let end = start+PAGE_PER_NUM;
        // 마지막페이지
        if(start < 0) { 
            start = 0;
            end = start+(totalCount%PAGE_PER_NUM);
        }
    },[pageNum]);


    return (
        <BorderTemplate>
        {
            <>
                <BoardList dataList={dataList}>
                    <Pagination pageNum={pageNum} setPageNum={setPageNum} totalCount={totalCount} PAGE_PER_NUM={PAGE_PER_NUM}/>
                </BoardList>                
            </>
        }
        </BorderTemplate>
    );
}

export default BoardPointHistory;