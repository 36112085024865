import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosGet, axiosPost } from '../../../apis/axios';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import SupportTemplate from '../../../components/SupportTemplate';
import VideoCard from '../../../components/VideoCard';

const UploadVideosTemplate = styled.fieldset`
    width: 100%;
    max-width: 777px; 
    padding: 20px;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    >div{
        margin: 0px 0px 20px 0px;
    }
    label{
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 14px;
        text-align: center;
    }
`;

const SubTitle = styled.legend`
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
`;

const TextareaTemplate = styled.textarea`
    width: 100%;
    height: 100px;
    padding: 10px;
    resize: none;
    border: 1px solid #D9DDE2;
    &:disabled {
        background: #fff;
        color: #ccc;
    }
`;

const MainVideo = () => {
    const [more, setMore] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState([]);

    const [youtubeCode, setYoutubeCode] = useState('');
    const [titleKr, setTitleKr] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleCn, setTitleCn] = useState(''); 
    const [details, setDetails] = useState('');

    useLayoutEffect(()=>{
        getItems();
    },[]);

    const getItems = () => {
        axiosGet('mainVideo/select')
            .then(response => {
                if(JSON.parse(response.data.result)){
                    const list = response.data.data;
                    setSelectedVideo(list.filter(el => el.activate));
                    setVideoList(list.filter(el => !el.activate));
                    return;
                }
                alert(response.data.message);
            })
            .catch(error => {
                console.error(error);
            })
    }

    const UploadData = () => {
        if(youtubeCode.trim() === '') {
            alert('Youtube 코드를 입력해주세요.');
            return;
        }

        if(titleKr.trim() === ''){
            alert('타이틀(한국어)을 입력해주세요.');
            return;
        }

        if(titleEn.trim() === ''){
            alert('타이틀(영어)을 입력해주세요.');
            return;
        }

        if(titleCn.trim() === ''){
            alert('타이틀(중국어)을 입력해주세요.');
            return;
        }

        const json = {
            youtubeCode,
            titleKr,
            titleEn,
            titleCn,
            details
        }

        axiosPost('mainVideo/insert', json)
            .then(response => {
                alert(response.data.message);
                if(JSON.parse(response.data.result)) {
                    window.location.href = '/main_video';
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <SupportTemplate>
            <h1>메인페이지 관리</h1>
            <div>
                <UploadVideosTemplate>
                <SubTitle onClick={e => setMore(!more)}>메인 동영상 등록 {more ? "닫기" : "열기"} (CLICK!)</SubTitle>
                    { more &&
                    <>
                        <div>
                            <label htmlFor="youtube_code">youtube 코드(required)</label>
                            <Input id="youtube_code" type="text" value={youtubeCode} onChange={e => setYoutubeCode(e.target.value)} placeholder="공유 버튼 클릭시 나타나는 URL 맨 뒤의 코드" width="100%" />
                        </div>
                        <div>
                            <label htmlFor="subject_kr">메인화면 타이틀 한국어(required) : </label>
                            <Input id="subject_kr" type="text" value={titleKr} onChange={e => setTitleKr(e.target.value)} placeholder="한국어 타이틀을 입력해주세요." width="100%" />
                        </div>
                        <div>
                            <label htmlFor="subject_en">메인화면 타이틀 영어(required) : </label>
                            <Input id="subject_en" type="text" value={titleEn} onChange={e => setTitleEn(e.target.value)} placeholder="영어 타이틀을 입력해주세요." width="100%" />
                        </div>
                        <div>
                            <label htmlFor="subjec_cn">메인화면 타이틀 중국어(required) : </label>
                            <Input id="subjec_cn" type="text" value={titleCn} onChange={e => setTitleCn(e.target.value)} placeholder="중국어 타이틀을 입력해주세요." width="100%" />
                        </div>
                        <div>
                            <label htmlFor="details">설명 : </label>
                            <TextareaTemplate id="details" value={details} onChange={e => setDetails(e.target.value)} placeholder="메인 동영상 및 해당 이벤트에 대한 내용을 입력해주세요."/>
                        </div>
                        <Button width="100%" onClick={UploadData}>등록</Button>
                    </>

                
                    }
                </UploadVideosTemplate>
            </div>

            <div style={{flexDirection: 'row'}}>
                    {   selectedVideo.length !== 0 && 
                            selectedVideo.map((el, index) => <VideoCard key={index} item={el}/>)
                    }
                    {
                        videoList.length !== 0 
                            && videoList.map((el, index) => <VideoCard key={index} item={el}/>)
                    }
            </div>
        </SupportTemplate>
    );
}

export default MainVideo;