import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { axiosPost } from '../../apis/axios';
import SendBoard from '../sendBoard/SendBoard';
import './SendMessage.css'

const SendMessage = () => {
    // INPUT값 넣기
    const [mesTitle, setMesTitle] = useState("");
    const [mesText, setMesText] = useState("");
    const [mesPhone, setMesPhone] = useState("");
    const [mesName , setMesName] = useState("")

    // modal toggle
    const [messageBoard , setMessageBoard] = useState(false)
    // message user 
    const [messageUser , setMessageUser] = useState("")

    const [ userRemoveText, setUserRemoveText ] = useState(false)

    // 회원탈퇴 메시지 불러오기 기능
    const userRemove = (e) => {
        e.preventDefault()
        setUserRemoveText(true)
        if(mesText !== ""){
            setMesText(mesText + userRemoveareaText())
        }else if(mesText === ""){
            setMesText(userRemoveareaText())
        }
    }
    // 회원탈퇴 메시지 누를때
    const userRemoveareaText = () => {
        return "데이터 삭제 및 탈퇴처리가 모두 완료되었습니다. 이용해주셔서 감사합니다."
    }

    const messageToggle = (e) => {
        e.preventDefault();
        setMessageBoard(true)
    }
    const messageSend = (e) => {
        // 버튼 누를때 새로고침 제거
        e.preventDefault()

        // 회원선택에서 값을 받아오면 담고, 아니면 mesPhone에 담긴 input입력값을 담는다.
        let phone = messageUser?.mobile ? messageUser?.mobile : mesPhone
        // 회원선택에서 값을 받아오면 담고, 아니면 mesName에 담긴 input입력값을 담는다.
        let name = messageUser?.nameKr ? messageUser?.nameKr : mesName
        
        if((messageUser?.mobile ? messageUser?.mobile : mesPhone) === ""){
            alert("번호를 입력하세요")
        }else if(((mesText === "" && userRemoveText === true) ?  userRemoveareaText() : mesText) === ""){
            alert("내용을 입력하세요")
        }else {
            if(window.confirm(
                `문자를 ${name}(${phone})고객에게 아래 내용으로 전송하시겠습니까?` + `\n` +
                `\n` +
                `${mesTitle}` + `\n`  +
                `${(mesText === "" && userRemoveText === true) ?  userRemoveareaText() : mesText}`)){
                axiosPost(`send/sms/sending`, {
                    'smsText' : (mesText === "" && userRemoveText === true) ?  userRemoveareaText() : mesText,
                    'smsTitle' : mesTitle === "" ? "[메타렉스]" : mesTitle,
                    'userMobile' : messageUser?.mobile ? messageUser?.mobile : mesPhone
                })
                .then(response => {
                    if(response){
                        alert(response.data?.message);
                    }
                })
                .catch(error => console.error(error));
            }   
        }
    }

    return (
        <section className='messageContainer'>
            <form action="">
                <ul>
                    <li>
                        <h3>문자 발송하기</h3>
                    </li>
                    <li>
                        <label>제목</label>
                        <input name="smsTitle" type="text" onChange={(e)=> setMesTitle(e.target.value)} defaultValue="[메타렉스]" autoComplete="off"/>
                    </li>
                    <li>
                        <label>회원번호</label>
                        <input name="회원번호" type="text" autoComplete="off" defaultValue={messageUser?.userNo} disabled/> 
                    </li>
                    <li>
                        <label>이름</label>
                        <input name="이름" type="text" onChange={(e) => setMesName(e.target.value)} defaultValue={messageUser?.nameKr} autoComplete="off"/>
                    </li>
                    <li>
                        <label>휴대폰번호</label>
                        <input name="userMobile" type="text" onChange={(e) =>setMesPhone(e.target.value)} defaultValue={messageUser?.mobile}  autoComplete="off"/>
                    </li>
                    <li>
                        <button onClick={messageToggle}>회원 선택</button>
                    </li>
                    <li>
                        <label>내용</label>
                        <textarea name="smsText" type="text" onChange={(e) => setMesText(e.target.value)} 
                        value = {mesText}>
                        </textarea>
                    </li>
                    <li>
                        <button onClick={messageSend}>전송</button>
                    </li>
                    <li>
                        <hr />
                    </li>
                    <li>
                        <button onClick={userRemove}>회원탈퇴 메시지 불러오기</button>
                    </li>
                </ul>
            </form>
            {
                messageBoard === true &&
                <SendBoard setMessageBoard={setMessageBoard} messageBoard={messageBoard} setMessageUser={setMessageUser}  isClipboardEnabled={false}></SendBoard>
            }
        </section>
    );
};

export default SendMessage;