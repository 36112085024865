import React from "react";
import styled from "styled-components";

const StyledSupportTemplate = styled.div`
    width: 100vw;
    height: auto;
    padding-top: 60px;
    margin-bottom: 180px;
    display: flex;
    flex-direction: column;
    h1 {
        padding-top: 80px;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 50px;
    }
    >div { 
        width: 100%;
        display:flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    @media only screen and (max-width: 768px) {
        margin-bottom: 60px;
        h1 {
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
        }
    }
`;

const SupportTemplate = ({children}) => {
    return (
        <StyledSupportTemplate>
            {children}
        </StyledSupportTemplate>
    )
}

export default SupportTemplate;