import React from 'react';

const RegistrationForsale = ({setRetouch , setKakaoModalToggle}) => {

    let registClick = () => {
        setRetouch(true)
        setKakaoModalToggle(false)
    }

    return (
        <>
            <div>
                <li>
                    <span>성격</span>
                    <span>마케팅 문구</span>
                </li>
                <li>
                    <span>주소 : </span>
                </li>
                <li>
                    <span>면적 : </span>
                </li>
                <li>
                    <span>소개문구 : </span>
                </li>
                <li>
                    <span>매입가 : </span>
                </li>
                <div>
                    <button onClick={registClick}>수정</button>
                    <button>삭제</button>
                </div>
            </div>
            <div>
                <li>
                    <span>성격</span>
                    <span>마케팅 문구</span>
                </li>
                <li>
                    <span>주소 : </span>
                </li>
                <li>
                    <span>면적 : </span>
                </li>
                <li>
                    <span>소개문구 : </span>
                </li>
                <li>
                    <span>매입가 : </span>
                </li>
                <div>
                    <button onClick={registClick}>수정</button>
                    <button>삭제</button>
                </div>
            </div>
            <div>
                <li>
                    <span>성격</span>
                    <span>마케팅 문구</span>
                </li>
                <li>
                    <span>주소 : </span>
                </li>
                <li>
                    <span>면적 : </span>
                </li>
                <li>
                    <span>소개문구 : </span>
                </li>
                <li>
                    <span>매입가 : </span>
                </li>
                <div>
                    <button onClick={registClick}>수정</button>
                    <button>삭제</button>
                </div>
            </div>
            <div>
                <li>
                    <span>성격</span>
                    <span>마케팅 문구</span>
                </li>
                <li>
                    <span>주소 : </span>
                </li>
                <li>
                    <span>면적 : </span>
                </li>
                <li>
                    <span>소개문구 : </span>
                </li>
                <li>
                    <span>매입가 : </span>
                </li>
                <div>
                    <button onClick={registClick}>수정</button>
                    <button>삭제</button>
                </div>
            </div>
        </>
    );
};

export default RegistrationForsale;