import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { axiosGet } from "../../../apis/axios";
import PageTemplate from "../../../components/PageTemplate";
import ItemCard from "../../../components/payment/ItemCard";
import ItemPointWithdrawUnconfirmed from "../../../contents/payments/ItemPointWithdrawUnconfirmed";
import { MOCK_DATA_FOR_P2P_REQUEST } from "../../../mockData";

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    >p{
        display: inline;
    }
`;

const ItemPointWithdrawApproval = () => {
    const [requestList, setRequestList] = useState([]);
    useEffect(() => {
        let p2pApprovalUrl = `point/withdraw/unconfirmed-items?pageNum=1&numPerPage=10`;
        axiosGet(p2pApprovalUrl)
        .then(response => {
            if(JSON.parse(response.data.result)) {   
                console.log('thkim 20220725 %o', response?.data);
                setRequestList(response?.data?.data);
            } else {
                alert(response.data.message);
                if( response.data?.href ) {                    
                    removeCookieAPI();
                    document.location.href = response.data?.href;
                }
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
    },[]);

    return (
        <PageTemplate>
            <TitleDiv>                
                <p>Point 출금 처리</p>
                <span>사용자가 point 출금한 내역을 보신 후, 출금 후 '출금 완료 처리' 또는 '출금 취소 처리'를 진행해주세요.</span>
            </TitleDiv>
            {/*requestList !== null && typeof requestList !== 'undefined' && Object.keys(requestList?.data).length === 0 ?*/}
            {
                requestList?.length == 0?
                <h2>
                    목록이 없습니다.
                </h2>
                : 
                requestList?.map((el,index) => <ItemCard key={index}><ItemPointWithdrawUnconfirmed data={el}/></ItemCard>)
            }
        </PageTemplate>
    );
}

export default ItemPointWithdrawApproval;