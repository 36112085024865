import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyleLogoTemplate = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    img {
        width: 110px;
        height: 18px;
    }
`;

const Logo = () => {
    return (
        <Link to="/main">
            <StyleLogoTemplate>
                <img src={`${process.env.PUBLIC_URL}/images/logo/metaREX_logo.svg`} alt="Metarex" title="Metarex" media-simple="true"/>
            </StyleLogoTemplate>
        </Link>
    )
}

export default Logo;