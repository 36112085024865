import React, { useState } from "react";
import { axiosPost } from "../../apis/axios";
import { addComma } from "../../apis/common";
import Button from "../../components/Button";
import LabelContents from "../../components/payment/LabelContents";

const ItemPointWithdrawUnconfirmed = ({data}) => {
    const [more, setMore] = useState(false);

    const p2pApprovalSend = () => {
        let p2pSendUrl = `asterpay/point/deposit-finish`
        return false;
        axiosPost(p2pSendUrl,{
            'paymentRequestNo' : data.paymentRequestNo
        })
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.reload()
            }
        })
        .catch(error => console.error(error));
    }
    const p2pApprovalCancel = () => {
        let p2pCancleUrl = `asterpay/point/deposit-rejecting`
        return false;
        axiosPost(p2pCancleUrl,{
            'paymentRequestNo' : data.paymentRequestNo
        })
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.reload()
            }
        })
        .catch(error => console.error(error));
    }
    return (
        <>
            <ul>
                <li><LabelContents title="결제 상태"><span>{data.pointWithdrawHistoryDetailTypeName}</span></LabelContents></li>
                <li><LabelContents title="거래소"><span>{data.exchangeNameKr}</span></LabelContents></li>
                <li><LabelContents title="회원명"><span>{data.customerName}</span></LabelContents></li>
                <li><LabelContents title="출금 요청한 포인트"><span>{addComma(data.orderNumber)}</span></LabelContents></li>
                <li><LabelContents title="입금해야할 아스터코인"><span>{addComma(data.paymentCoinAmount)} ATC</span></LabelContents></li>
                <li><LabelContents title="원화 가격"><span>{addComma(data.amount)} 원</span></LabelContents></li>
                <li><LabelContents title="주문 시각"><span>{data.insertedTs}</span></LabelContents></li>

                {/* 접기/더보기 버튼 만들기 */}
                <div><button onClick={e=>setMore(!more)}>[ more ]</button></div>
                {
                    more &&
                        <>
                        <li><LabelContents title="주문 번호"><span>{data.orderId}</span></LabelContents></li>
                        <li><LabelContents title="결제 번호"><span>{data.paymentRequestNo}</span></LabelContents></li>                        
                        <li><LabelContents title="회원 휴대폰"><span>{data.mobile}</span></LabelContents></li>
                        <li><LabelContents title="회원 이메일"><span>{data.email}</span></LabelContents></li>                        
                        <li><LabelContents title="기준 코인시세"><span>{addComma(data.coinUsd)}</span><br/><span>{data.coinKrw} 원</span></LabelContents></li>
                        <li><LabelContents title="기준 원달러 시세"><span>{addComma(data.wonUsd)} 원</span></LabelContents></li>                        
                        </>
                }
            </ul>
            <Button width="100%" onClick={p2pApprovalSend}>사용자 출금 완료 처리</Button>
            <p style={{height:'13px'}}></p>
            <Button width="100%" onClick={p2pApprovalCancel}>사용자 출금 취소 처리</Button>
        </>
    )
}

export default ItemPointWithdrawUnconfirmed