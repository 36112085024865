import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "../components/header/Header";
import Main from "./Main";
import Questions from "./management/questions/Questions";
import ReplyQuestions from "./management/questions/ReplyQuestion";
import EditBoard from "./management/EditBoard";
import WriteBoard from "./management/WriteBoard";
import Notice from "./notice/Notice";

import ItemPointBuyApproval from "./payment/point/ItemPointBuyApproval"
import ItemPointPaymentAndDeduction from "./payment/point/ItemPointPaymentAndDeduction"
import ItemPointWithdrawApproval from "./payment/point/ItemPointWithdrawApproval"

import ItemPointPaymentHistory from "./payment/point/ItemPointPaymentHistory"

import ItemPaymentApproval from "./payment/ItemPaymentApproval"
import ItemPaytmentExpired from "./payment/ItemPaytmentExpired";
import ItemPaymentHistory from "./payment/ItemPaymentHistory";
import P2pRequestApproval from "./payment/P2pRequestApproval";
import P2pRequestExpired from "./payment/P2pRequestExpired";
import UserRequestApproval from "./payment/UserRequestApproval";
import UserPaymentApproval from "./payment/UserPaymentApproval";
import RecomHome from "./recommendation/RecomHome";
import SendMessage from "./sendMessage/SendMessage";
import SendEmail from "./sendEmail/SendEmail";
import SendBoard from "./sendBoard/SendBoard";
import SignIn from "./SignIn";
import Faq from "./management/faq/Faq";
import Videos from "./management/videos/Videos";
import HotIssues from "./management/hotIssues/HotIssues";
import MainVideo from "./management/mainVideo/MainVideo";

{/* 사이트 관리 */}
{/* 매물 관리 */}
    {/* 보라카메라 등록 */}
    import BoraCamera from "./itemManagement/BoraCamera";
    {/* 팔린 매물 보기 */}
    import SoldoutItemList from "./itemManagement/SoldoutItemList";
    
{/* 결제 관리 */}
{/* 암호화폐 관리 */}
{/* 회원 관리 */}
{/* 발송*/}

const AppRouter = ({isLoggedIn , sessionConfirm}) => {
    useEffect(()=>{
        if(sessionConfirm === true && window.location.pathname !== "/"){
            alert(isLoggedIn?.data.message);
            window.location.href= '/';
            return;
        }
    },[sessionConfirm])

    return (
        <BrowserRouter>
            {sessionConfirm === false && <Header/> }
            <Routes>
                {sessionConfirm === false
                    ?
                    <>
                        <Route path="/main" element={<Main />} />

                    {/* 사이트 관리 */}
                    {/* 매물 관리 */}
                        {/* 보라카메라 등록 */}
                        <Route path="add-bora-camera" element={<BoraCamera />}/>
                        {/* 팔린 매물 보기 */}
                        <Route path="soldout-item-list" element={<SoldoutItemList />}/>

                    {/* 결제 관리 */}
                    {/* 암호화폐 관리 */}
                    {/* 회원 관리 */}
                    {/* 발송*/}
                        {/* 부동산 핫이슈 */}
                        <Route path="hotissue" element={<hotissues />}/>
                        {/* FAQ */}
                        <Route path="faq" element={<Faq />}/>
                        {/* 자료실 */}
                        <Route path="videos" element={<Videos />}/>
                        {/* 메인페이지-비디오 */}
                        <Route path="main_video" element={<MainVideo />}/>

                        {/* 자율 구매 매물 */}
                        <Route path="approval" element={<UserRequestApproval />} />
                        <Route path="selfApproval" element={<UserPaymentApproval />} />

                        {/* 일반 & 추천 매물 */}
                        <Route path="recommendation" element={<RecomHome />} />


                        {/* Point */}
                        <Route path="item-point-purchase-processing" element={<ItemPointBuyApproval />}/>
                        <Route path="item-point-payment-and-deduction" element={<ItemPointPaymentAndDeduction />}/>
                        <Route path="item-point-withdraw-processing" element={<ItemPointWithdrawApproval />}/>
                        <Route path="item-point-payment-history" element={<ItemPointPaymentHistory />}/>

                        {/* 일반 & 추천 매물 결제 */}
                        <Route path="item_payment" element={<ItemPaymentApproval />} />
                        <Route path="item_expired" element={<ItemPaytmentExpired />} />
                        <Route path="item_history" element={<ItemPaymentHistory />} />

                        {/* P2P 매물 */}
                        <Route path="p2p_payment" element={<P2pRequestApproval />} />
                        <Route path="p2p_expired" element={<P2pRequestExpired />} />

                        {/* 문의내역 */}
                        <Route path="questions" element={<Questions />}/>
                        <Route path="reply_questions" element={<ReplyQuestions />}/>

                        {/* 공지사항 */}
                        <Route path="notice" element={<Notice />}/>
                        <Route path="write" element={<WriteBoard />}/>
                        <Route path="edit" element={<EditBoard />}/>

                        {/* 발송 */}
                        <Route path="send_message" element={<SendMessage />}/>
                        <Route path="send_email" element={<SendEmail />}/>
                        <Route path="send_board" element={<SendBoard />}/>
                    </>
                    : <Route path="/" exact={true} element={<SignIn /> }/>
                }
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
