import React from "react";
import styled from "styled-components";

const LabelContentsTemplate = styled.div`
    width: 100%;
    margin: 10px 0px;
`;

const StyledLabel = styled.label`
    display:block;
    font-weight: bold;
    margin-bottom: 3px;
    &::after{
        content:':';
        margin-left:5px;
    }
`;

const StyledDiv = styled.div`
    >select {
        width: 100%;
        height: 30px;
        border-radius: 0px;
        &:focus {
            outline: none;
        }
        option {
            height: 30px;
        }
    }

    >span {
        margin-left: 20px;
        // 여기 바꿈
        display:inline-block;
        word-break:break-all;
    }
`;


const LabelContents = ({title, children}) => {
    return (
        <LabelContentsTemplate>
            <StyledLabel htmlFor={title}>{title}</StyledLabel>
            <StyledDiv id={title}>{children}</StyledDiv>
        </LabelContentsTemplate>
    );
}

export default LabelContents;