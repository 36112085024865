import React from "react";
import styled from "styled-components";

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    tr {
        td {
            padding: 5px 0px;
            height: 88px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: -0.02em;
            text-align: left;
            &:first-of-type {
                width: 97px;
                text-align: center;
            }
            &:last-of-type {
                width: 80px;
                text-align: center;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
            }

        }
    }
    tbody {
        tr {
            cursor: pointer;
            border-bottom: 1px solid #D9DDE2;
            &:hover {
                td {
                    color:#2f80ed;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        tr {
            display: flex;
            flex-direction: column-reverse;
            height: 80px;
            td {
                height: auto;
                padding: 0px;
                font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: -0.02em;
                text-align: left;
                margin-bottom: 23px;
                &:first-of-type {
                    display: none;
                }
                &:last-of-type {
                    width: 100%;
                    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: -0.02em;
                    text-align: left;
                    margin-bottom: 6px;
                }
            }
        }
        tbody {
            tr {
                margin: 0px 20px;
            }
        }
    }
`;

const BoardList = ({children, dataList, viewDetail}) => {
    return (
        <>
        <StyledTable>
            <tbody>
                {dataList.map((data, index) => (
                    <tr key={index} onClick={viewDetail}>
                        <td>{data.boardNoticeNo 
                                ? (data.boardNoticeNo < 10 ? `0${data.boardNoticeNo}` : data.boardNoticeNo)
                                : data.boardContactUsNo 
                                    ? (data.boardContactUsNo < 10 ? `0${data.boardContactUsNo}` : data.boardContactUsNo)
                                    : (data.boardMetarexFaqNo < 10 ? `0${data.boardMetarexFaqNo}` : data.boardMetarexFaqNo)
                                }
                        </td>
                        <td>{data.subjectKr ? data.subjectKr : data.subject}</td>
                        <td>{data.insertedTs.split(" ")[0]}</td>
                    </tr>
                ))}
            </tbody>
        </StyledTable>
        {children}
        </>
    );
}

export default BoardList;