import { TOGGLE_MENU } from "./type";

const toggleReducer = (state = false, action) => {
    switch(action.type) {
        case TOGGLE_MENU:
            return {
                ...state,
                data: action.data
            };
        default: 
            return state;
    }
}

export default toggleReducer;