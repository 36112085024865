import React, {useLayoutEffect, useState} from "react";
import styled from "styled-components";
import { requestItemApproval } from "../../apis/approval";
import PageTemplate from "../../components/PageTemplate";
import ItemCard from "../../components/payment/ItemCard";
import ItemApproval from "../../contents/payments/ItemApproval";
import { MOCK_DATA_FOR_REQUEST_APPROVAL } from "../../mockData";
import { axiosGet } from '../../apis/axios';

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    >p{
        display: inline;
    }
`;

const RequestItemApproval = () => {
    const [requestList, setRequestList] = useState();


    useLayoutEffect(()=>{
        let requestApprovalUrl = `realestate/getRequestItemNotApproval?pageNum=1&numPerPage=10`
        axiosGet(requestApprovalUrl)
        .then(res => setRequestList(res.data.data))
        .catch(error => console.error(error));
        // setRequestList(MOCK_DATA_FOR_REQUEST_APPROVAL);
    },[]);

    return (
        <PageTemplate>
            <TitleDiv>                
                <p>사용자 자율구매 매물 승인</p>
                <span>'매물 승인 처리' 또는 '매물 거절 처리'를 진행해주세요.</span>
            </TitleDiv>
            {
                requestList?.length === 0 ?
                <h2>
                    매물이 없습니다.
                </h2>
                : 
                requestList?.map((el,index) => 
                <ItemCard key={index}><ItemApproval data={el}/></ItemCard>)
            }
        </PageTemplate>
    );
}

export default RequestItemApproval;