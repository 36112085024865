import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import './RecomMap.css';
import KakaoMap from './KakaoMap';

const RecomMap = ({ keyword, setAddress, listClickAddress, kakaoSearchAddress ,  setSearchType , searchType ,page }) => {
    
    useEffect(() => {
        // const kakaoMaps = KakaoMap(keyword, setAddress, listClickAddress , kakaoSearchAddress, setSearchType , searchType);
        KakaoMap(keyword, setAddress, listClickAddress , kakaoSearchAddress, setSearchType , searchType , page);
    }, [keyword , setSearchType , setAddress , kakaoSearchAddress , page]);

    return (
        <div>
            <div id="map" className={'maps'}></div>
        </div>
    );
};

export default RecomMap;