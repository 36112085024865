import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import queryString from 'query-string';
import { axiosPost } from "../../apis/axios";
import Button from "../Button";
import BoardContent from "./BoardContent";
import BoardList from "./BoardList";
import Pagination from "./Pagination";
import { isNull } from "../../apis/common";

const BorderTemplate = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
`;

const StyledNoticeDetail = styled.div`
    padding: 40px 0px 200px 90px;
    margin-bottom: 60px;
    border-bottom: 1px solid #D9DDE2;
    width: 100%;
    >div {
        flex-direction: column;
        width: 100%;
    }
    >p { 
        width: 100%;
    }
    @media only screen and (max-width: 768px) {
        padding: 40px 20px 50px 20px;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.02em;
        text-align: left;

        div {
            margin-bottom: 50px;
        }
        margin-bottom: 20px;
    }
`;

const ButtonTemplate = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Board = ({dataList, pageNum, setPageNum, totalCount, PAGE_PER_NUM, url}) => {
    const [openPage, setOpenPage] = useState(false);
    const [openPageContent, setOpenPageContent] = useState({});
    const [resultData, setResultData] = useState([]);
    const [isQuestion, setIsQuestion] = useState(false);
    const parsed = queryString.parse(window.location.search);
    const navigate = useNavigate();
    const [firstRender, setFirstRender] = useState(true);

    useEffect(()=>{
        const boardNo = parsed.boardNo;

        if(!isNull(boardNo)){
            setOpenPage(true);
            let content = dataList.filter(el => Number(el.boardNoticeNo) === Number(boardNo));

            if(content.length <= 0) {
                content = dataList.filter(el => Number(el.boardMetarexFaqNo) === Number(boardNo));
                if(content.length <= 0) {
                    window.location.href=url;
                    return;    
                }
            }
            setOpenPageContent(content[0]);
            return;
        }

        if(firstRender) {
            setFirstRender(false);
            return;
        }

        setOpenPage(false);        
        navigate(url);
    }, [parsed.boardNo]);

    const viewDetail = (e) => {
        const inputData = e.currentTarget.children[0].textContent;

        let boardNo;
        let content;

        const boardContent = dataList.filter(el => +(el.boardNoticeNo) === +inputData );

        if(boardContent.length === 0) {
            content = dataList.filter(el => +(el.boardMetarexFaqNo) === +inputData );
            boardNo = content[0].boardMetarexFaqNo;
        } else {
            content = boardContent;
            boardNo = content[0].boardNoticeNo;
        }

        setOpenPageContent(content[0]);
        setOpenPage(true);

        window.history.pushState('','',`${url}?boardNo=${boardNo}`);
    }

    //문의사항 클릭이벤트
    const viewDetailQuestion = (e) => {
        const contentsNo = Number(e.currentTarget.children.item(0).textContent.split()); 
        navigate(`/reply_questions?idx=${contentsNo}`);
    }
    
    useEffect(()=>{
        let start = totalCount - (PAGE_PER_NUM * pageNum);
        let end = start+PAGE_PER_NUM;
        // 마지막페이지
        if(start < 0) { 
            start = 0;
            end = start+(totalCount%PAGE_PER_NUM);
        }

        // 문의(boardContactUsNo) vs 공지(boardNoticeNo) vs FAQ(boardMetarexFaqNo)
        let list;

        if(!isNull(dataList[0].boardNoticeNo) || typeof dataList[0].boardNoticeNo !== 'undefined'){
            list = dataList
            .sort((a, b) => a.boardNoticeNo - b.boardNoticeNo)
            .slice(start, end);
        } else if(!isNull(dataList[0].boardMetarexFaqNo) || typeof dataList[0].boardMetarexFaqNo !== 'undefined') {
            list = dataList
            .sort((a, b) => a.boardMetarexFaqNo - b.boardMetarexFaqNo)
            .slice(start, end);
        } else {
            setIsQuestion(true);
            list = dataList
            .sort((a, b) => a.boardContactUsNo - b.boardContactUsNo)
            .slice(start, end);
        }
        setResultData(list.reverse());
    },[pageNum]);

    const deleteBoard = () => {
        if (window.confirm(`해당 게시글을 삭제하시겠습니까?`)) {
            const boardNo = openPageContent.boardNoticeNo ? openPageContent.boardNoticeNo : openPageContent.boardMetarexFaqNo;
            axiosPost(`${url.replace("/", "")}/delete`, { boardNo })
                .then(response => {
                    alert(response.data.message);
                    if(JSON.parse(response.data.result)) {
                        window.location.href=url;
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    const editNotice = () => {
        const boardNo = openPageContent.boardNoticeNo ? openPageContent.boardNoticeNo : openPageContent.boardMetarexFaqNo;
        navigate(`/edit?type=${url.replace("/", "")}&boardNo=${boardNo}`)
    }

    return (
        <BorderTemplate>
        { openPage 
            ? <BoardContent>
                {
                    openPageContent &&
                    <>
                        <div className="subject">
                            <span>{
                                !isNull(openPageContent.boardNoticeNo) 
                                ? (openPageContent.boardNoticeNo < 10 ? `0${openPageContent.boardNoticeNo}` : openPageContent.boardNoticeNo)
                                : (openPageContent.boardMetarexFaqNo < 10 ? `0${openPageContent.boardMetarexFaqNo}` : openPageContent.boardMetarexFaqNo)
                            }</span>
                            <h2>{openPageContent.subjectKr}</h2>
                            <span>{openPageContent.insertedTs.split(" ")[0]}</span>
                        </div>
                        <StyledNoticeDetail>
                            <div dangerouslySetInnerHTML={{__html: openPageContent.bodyKr}}/>
                        </StyledNoticeDetail>
                        <div className="btn_list">
                            <div>
                                <Button width="100px" onClick={editNotice}>수정</Button>
                                <Button width="100px" onClick={deleteBoard}>삭제</Button>
                            </div>
                            <Button width="100px" onClick={e=> { setOpenPage(!openPage); navigate(url);}}>목록</Button>
                        </div>
                    </>
                }
            </BoardContent>
            : 
            <>
                <BoardList dataList={resultData} viewDetail={isQuestion?viewDetailQuestion:viewDetail}>
                    <Pagination pageNum={pageNum} setPageNum={setPageNum} totalCount={totalCount} PAGE_PER_NUM={PAGE_PER_NUM}/>
                </BoardList>
                {!isQuestion && <ButtonTemplate>
                                    <Link to={`/write?type=${url.replace("/", "")}`}><Button width="100px">글쓰기</Button></Link>
                                </ButtonTemplate>}
            </>
        }
        </BorderTemplate>
    );
}

export default Board;