import React from "react";
import styled from "styled-components";

const DropdownTemplate = styled.ul`
    background:#05141F;
    flex-direction:column;
    position:absolute;
    top:60px;
    > li{
        display:flex;
        align-items:center;
        height:60px;
        width:auto;
        min-width: 150px;
        a{
            width: 100%;
            padding:10px 20px;
            height:auto;
            color:#B0B0B0;
            &:hover {
                color: #fff;
                text-decoration:underline;
                text-underline-position:under;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        background:#fff;
        position: static;
        display: block;
        >li{
            >a{
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                color:#595959;
                &:hover {
                    color: #05141F;
                }
            }
        }
    }
`;

const Dropdown = ({children}) => {
    return (
        <DropdownTemplate>
            {children}
        </DropdownTemplate>
    );
}

export default Dropdown;