import { axiosGet } from './axios';
import { removeCookieAPI } from "./browser";

const isValidSessionId = (setIsLoggedIn) => {
        let isValidUrl = `api/user/isValidSessionId`
        axiosGet(isValidUrl)
        .then(response => {
                setIsLoggedIn(response);
                if(JSON.parse(response.data.result)){                                        
                } else {
                    alert(response.data.message);                    
                    removeCookieAPI();
                    if( response.data?.href ) {
                        //document.location.href = response.data?.href;
                        return true;
                    }
                }
            }).catch(e => {
                console.log(`error message: ${e}`);
            });
};

export default isValidSessionId;