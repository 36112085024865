import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { axiosGet } from "../../../apis/axios";
import PageTemplate from "../../../components/PageTemplate";
import BoardPointHistory from "../../../components/board/BoardPointHistory";
import ItemCard from "../../../components/payment/ItemCard";
import P2pApproval from "../../../contents/payments/P2pApproval";
import { MOCK_DATA_FOR_P2P_REQUEST } from "../../../mockData";

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    >p{
        display: inline;
    }
`;

const ItemPointPaymentHistory = () => {

    const [requestList, setRequestList] = useState([]);

    const PAGE_PER_NUM = 5;
    const [pageNum, setPageNum] = useState(1);

    const [totalCount, setTotalCount] = useState(0);
    

    useLayoutEffect(() => {
        let p2pApprovalUrl = `api/pay-management/payment-management/history?pageNum=${pageNum}&numPerPage=${PAGE_PER_NUM}`;
        axiosGet(p2pApprovalUrl)
        .then(response => {
            if(JSON.parse(response.data.result)){                
                setRequestList(response?.data?.data);
                setTotalCount(response?.data?.totalCount);
            } else {
                alert(response.data.message);
                if( response.data?.href ) {                    
                    removeCookieAPI();
                    document.location.href = response.data?.href;
                }
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
    },[]);

    return (
        <PageTemplate>
            <TitleDiv>                
                <p>Point 관리</p>
                <span>Point 내역이 여기에 보여집니다.</span>
            </TitleDiv>
            {
            requestList?.data?.length === 0 ?
                <h2>
                    목록이 없습니다.
                </h2>
            : 
                <div>
                    <BoardPointHistory dataList={requestList} pageNum={pageNum} setPageNum={setPageNum} totalCount={totalCount} PAGE_PER_NUM={PAGE_PER_NUM} url="/questions"/>
                </div>
            }
        </PageTemplate>
    );
}

export default ItemPointPaymentHistory;