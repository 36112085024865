import { useState } from "react";
import { axiosPost } from "../../apis/axios";
import { addComma } from "../../apis/common";
import Button from "../../components/Button";
import LabelContents from "../../components/payment/LabelContents";
import Screen from "../../components/Screen";


const ItemApproval = ({data}) => {
    const [more, setMore] = useState(false);
    const [onModal, setOnModal] = useState(false);

    const approvalSend = () => {
        let sendSelfUrl = `asterpay/requestItem/depositFinish`
        axiosPost(sendSelfUrl,{
            'paymentRequestNo' : data.paymentRequestNo
        })
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.reload()
            }
        })
        .catch(error => console.error(error));
    }
    const approvalCancel = () => {
        let cancleSelfUrl = `asterpay/requestItem/depositRejecting`
        axiosPost(cancleSelfUrl,{
            'paymentRequestNo' : data.paymentRequestNo
        })
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.reload()
            }
        })
        .catch(error => console.error(error));
    }
    return (
        <>
            {onModal && <Screen></Screen>}
            <ul>
                <li><LabelContents title="결제상태"><span>미확인</span></LabelContents></li>
                <li><LabelContents title="거래소"><span>{data.exchange}</span></LabelContents></li>
                <li onClick={e => setOnModal(!onModal)}><LabelContents title="회원명"><span>{data.customerName}</span></LabelContents></li>
                <li><LabelContents title="위치"><span>{data.orderName}</span></LabelContents></li>
                <li><LabelContents title="결제 아스터코인"><span>{`${addComma(data.paymentCoinAmount)} ATC`}</span></LabelContents></li>
                <li><LabelContents title="결제 거래소"><span>{data.exchange}</span></LabelContents></li>
                <li><LabelContents title="결제 지갑 주소"><span>{data.from}</span></LabelContents></li>
                <li><LabelContents title="주문 시각"><span>{data.insertedTs}</span></LabelContents></li>

                {/* 접기/더보기 버튼 만들기 */}
                <div><button onClick={e=>setMore(!more)}>[ more ]</button></div>
                {
                    more &&
                        <>
                            <li><LabelContents title="회원 휴대폰"><span>{data.mobile}</span></LabelContents></li>
                            <li><LabelContents title="회원 이메일"><span>{data.email}</span></LabelContents></li>
                            <li><LabelContents title="결제 번호"><span>{data.paymentRequestNo}</span></LabelContents></li>
                            <li><LabelContents title="원화 가격"><span>{`${addComma(data.amount)} 원`}</span></LabelContents></li>
                            <li>
                                <LabelContents title="기준 코인 시세">
                                    <span>${data.coinUsd}</span> <br/>
                                    <span>{`${addComma(data.coinKrw)} 원`}</span>
                                </LabelContents>
                            </li>
                            <li><LabelContents title="기준 원달러 시세"><span>{`${addComma(data.wonUsd)} 원`}</span></LabelContents></li>
                            <li><LabelContents title="주문 번호"><span>{data.orderId}</span></LabelContents></li>
                        </>
                }
            </ul>
            <Button onClick={approvalSend} width="100%">매물 승인 처리</Button>
            {/* 버튼 띄우려고 높이값줌 */}
            <p style={{height:'13px'}}></p>
            <Button onClick={approvalCancel} width="100%">매물 거절 처리</Button>
        </>
    )
}

export default ItemApproval;