import React from "react";
import styled from "styled-components";

const ItemCardTemplate = styled.div`
    width: 350px;
    height: auto;
    border: 1px solid black;
    padding: 15px;
    margin-bottom: 20px;
    >ul{
        >div{
            background: #D9DDE2;
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0px;
            >button{
                width: 100%;
                height: 100%;
                border: none;
                background: #D9DDE2;
                color: #05141F;
                font-size: 16px;
                cursor: pointer;
                &:hover{
                    transition: .2s;
                    color: #fff;
                }
            }
        }
    }
`;

const ItemCard = ({children}) => {
    return (
        <ItemCardTemplate>
            {children}
        </ItemCardTemplate>
    );
}

export default ItemCard;