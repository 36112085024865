import { useEffect, useState } from 'react';
import './App.css';
import isValidSessionId from './apis/isValidSessionId';

import AppRouter from './pages/AppRouter';
import { removeCookieAPI } from "./apis/browser";

const App = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState();

  // useEffect(()=>{
  //   setIsLoggedIn(localStorage.getItem('adminSessionId'));
  // },[isLoggedIn]);
	
	const [isLoggedIn , setIsLoggedIn] = useState()
	const [sessionConfirm, setSessionConfirm] = useState(false)
	
	
	useEffect(()=>{
		isValidSessionId(setIsLoggedIn)
	}, []);
	useEffect(()=>{
		if( (isLoggedIn?.data.message === "로그인이 필요합니다.") === true ) {
			setSessionConfirm(true);
			removeCookieAPI();
			return 
		}else if( isLoggedIn?.data.message === "" ) {
			return setSessionConfirm(false)
		}
		//
	}, [isLoggedIn]);

	return (
		<>
		<AppRouter isLoggedIn={isLoggedIn} sessionConfirm={sessionConfirm} >
		</AppRouter>
		</>
	);
}

export default App;
