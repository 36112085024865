import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { axiosGet } from "../../apis/axios";
import PageTemplate from "../../components/PageTemplate";
import ItemCard from "../../components/payment/ItemCard";
import P2pExpired from "../../contents/payments/P2pExpired";
import { MOCK_DATA_FOR_P2P_EXPIRED } from "../../mockData";

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    >p{
        display: inline;
    }
`;

const P2pRequestExpired = () => {
    const [expiredList, setExpiredList] = useState([]);
    useEffect(() => {
        let p2pExpiredUrl = `asterpay/history/getP2PExpiredPayment?pageNum=1&numPerPage=10`;
        axiosGet(p2pExpiredUrl)
        .then(res => setExpiredList(res.data))
        .catch(err => console.log(err))
        //setExpiredList(MOCK_DATA_FOR_P2P_EXPIRED);
    },[]);
    
    return (
        <PageTemplate>
            <TitleDiv>                
                <p>P2P 매물 결제 만료 처리</p>
                <span>결제 시간 만료에 따른 '결체 취소 처리'를 진행해주세요.</span>
            </TitleDiv>
            {
                expiredList?.data?.length === 0 ?
                <h2>
                    매물이 없습니다.
                </h2>
                : 
                expiredList?.data?.map((el,index) => <ItemCard key={index}><P2pExpired data={el}/></ItemCard>)
            }
        </PageTemplate>
    );
}

export default P2pRequestExpired;