import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const StyledPagenationDiv = styled.div`
    padding: 80px 0px 0px 0px;
    display: flex;
    align-items: center;
    .fa-chevron-right,
    .fa-chevron-left {
        cursor: pointer;
        &.disabled {
            color: #D9DDE2;
            cursor: auto;
        }
    }
    .pagination {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            margin: 0px 10px;
            font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: -0.02em;
            text-align: left;
            cursor: pointer;
            &.clicked {
                color: #2f80ed;
                cursor: auto;
            }
        }
    }


`;

const Pagination = ({pageNum, setPageNum, totalCount, PAGE_PER_NUM}) => {
    const [pageCount, setPageCount] = useState(0); // 총 페이지의 수
    const [pageNav, setPageNav] = useState([]);
    const [startPage, setStartPage] = useState(1);
    const MAXIMUM_PAGE_COUNT = 5;


    useEffect(()=>{
        setPageCount(Math.ceil(totalCount / PAGE_PER_NUM));
        pageNumber();
    }, [pageNum, totalCount, pageCount]);

    const pageNumber = () => {
        const array = [];
        for(let i = startPage; i < (startPage + MAXIMUM_PAGE_COUNT); i++) {
            if(pageCount < i) {
                break;
            }
            array.push(i);
        }
        setPageNav(array);
    }

    const onClickNext = (e) => {
        const nextStartPoint = (Math.ceil(pageNum/MAXIMUM_PAGE_COUNT) * MAXIMUM_PAGE_COUNT) + 1;
        if(pageCount <= nextStartPoint) { return; }

        setPageNum(nextStartPoint);
        setStartPage(nextStartPoint);
    }

    const onClickPrev = (e) => {
        if(pageNum <= MAXIMUM_PAGE_COUNT) { return; }
        const prevEndPoint = pageNum - (Math.ceil(pageNum%MAXIMUM_PAGE_COUNT) === 0 
                            ? MAXIMUM_PAGE_COUNT 
                            : Math.ceil(pageNum%MAXIMUM_PAGE_COUNT));
        setPageNum(prevEndPoint);
        setStartPage(prevEndPoint - MAXIMUM_PAGE_COUNT + 1);
    }

    const onClickPageNum = (e) => {
        if(+(e.target.textContent) === pageNum) {
            return;
        }
        setPageNum(+(e.target.textContent));
    }


    return (
        <StyledPagenationDiv>
            <FontAwesomeIcon icon={faChevronLeft} onClick={onClickPrev} className={startPage <= 1 ? "disabled" : ""}/>
            <div className="pagination">
                { pageNav.length !== 0 && pageNav.map((el, index) => <span key={index} onClick={onClickPageNum} className={el === pageNum ? "clicked" : ""}>{el}</span>)}
            </div>
            <FontAwesomeIcon icon={faChevronRight} onClick={onClickNext} className={pageCount <= startPage + MAXIMUM_PAGE_COUNT ? "disabled" : ""}/>
        </StyledPagenationDiv>
    )
}

export default Pagination;