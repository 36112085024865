export const addComma = (price) => {
    price = String(price);
    if(price === "undefined" || price === 'null' || price === "" ) {
        return "0";
    }

    let decimal = '';
    let num = price.replace(/,/gi, "");

    let isNegative = false;
    if(num.indexOf('-') === 0 ) {
        isNegative = true;
        num = num.replace(/-/gi, "");
    }

    if(num.indexOf('.') > -1 ) {
        let arrSplitDecimalPoint = num.split('.');
        num = arrSplitDecimalPoint[0];
        decimal = '.' + arrSplitDecimalPoint[1];
    }

    var num2 = num.split(/(?=(?:\d{3})+$)/).join(",") + decimal;

    if( isNegative === true ) {
        num2 = "-" + num2;
    }

    return num2;
}

export const isNull = (target) => {
    if(target === null || typeof target === 'undefined' || target === 'undefined') {
        return true;
    }
    return false;
}
