import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { axiosGet } from "../../apis/axios";
import PageTemplate from "../../components/PageTemplate";
import ItemCard from "../../components/payment/ItemCard";
import NewApproval from "../../contents/payments/NewApproval";

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    >p{
        display: inline;
    }
`;

const ItemPaymentApproval = () => {
    const [requestList, setRequestList] = useState([]);
    // "자율구매", "p2p매물", "추천매물"

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axiosGet("api/pay-management/payment-approval/no-checking?pageNum=1&numPerPage=10")
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setRequestList(response.data.data);
                    return;
                }
                alert(response.data.message);
            })
            .catch(error => console.error(error));
    }



    return (
        <PageTemplate>
            <TitleDiv>                
                <p>매물 결제 승인처리</p>
                <span>'입금 완료 처리' 또는 '결체 취소 처리'를 진행해주세요.</span>
            </TitleDiv>
            {
                requestList.length !== 0
                    ? requestList.map((el, index) => <ItemCard key={index}><NewApproval data={el}/></ItemCard>)
                    : <h2>매물이 없습니다.</h2>
            }


        </PageTemplate>
    );
}

export default ItemPaymentApproval;