import React from "react";
import styled from "styled-components";

const CapsLockForm = styled.div`
    height: 30px;
    vertical-align: middle;
    P {
        font-family: NanumSquare;
        color: #FF645A;
        font-size: 14px;
        text-align: center;
        padding: 5px 0px
    }
`;

const CapsLockArea = ({isCapsLock}) => {
    return (
        <CapsLockForm>
            {isCapsLock && <p>* WARNING! Caps lock is ON.</p>}
        </CapsLockForm>
    );
}

export default CapsLockArea;