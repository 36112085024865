import React, { useState } from "react";
import styled from "styled-components";
import { requstSignIn } from "../apis/auth";
import Button from "../components/Button";
import CapsLockArea from "../components/CapsLockArea";
import Input from "../components/Input";

const SignInTemplate = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        font-family: NanumGothic;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        color: #05141F;
        margin-bottom: 50px;
    }
`;

const SignIn = () => {
    const [adminUserId, setAdminUserId] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [isCapsLock, setIsCapsLock] = useState(false);

    const keyUpEvent = (e) => {
        // enter event
        if(window.event.code === 'Enter'){
            requstSignIn({adminUserId, userPassword});
            return;
        }
    
        // capsLock check
        setIsCapsLock(e.getModifierState("CapsLock"));
            
        // 한글 입력 불가
        e.target.value = e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
    }

    return (
        <SignInTemplate>
            <h1>로그인</h1>
            <Input 
                type="text" 
                onChange={(e) => setAdminUserId(e.target.value)}
                placeholder="아이디 입력 (@ 이후 도메인 생략 가능)"
            />
            <Input 
                type="password" 
                onChange={(e) => setUserPassword(e.target.value)}
                onKeyUp={keyUpEvent}
                placeholder="비밀번호 입력"
            />
            <CapsLockArea isCapsLock={isCapsLock}/>
            <Button onClick={() => requstSignIn({adminUserId, userPassword})}>로그인하기</Button>
        </SignInTemplate>
    );
}

export default SignIn;