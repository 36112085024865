import React, { useState } from "react";
import { axiosPost } from "../../apis/axios";
import { addComma } from "../../apis/common";
import Button from "../../components/Button";
import LabelContents from "../../components/payment/LabelContents";

const NewApproval = ({data}) => {
    const [more, setMore] = useState(false);

    // 승인
    const newApprovalSend = () => {
        axiosPost('api/pay-management/payment-approval/deposit-finish' ,{
            'paymentRequestNo' : data.paymentRequestNo
        })
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.reload()
            }
        })
        .catch(error => console.error(error));
    }

    // 거절
    const newApprovalCancel = () => {
        axiosPost('api/pay-management/payment-approval/deposit-rejecting',{
            'paymentRequestNo' : data.paymentRequestNo
        })
        .then(response => {
            alert(response.data.message);
            if(JSON.parse(response.data.result)){
                window.location.reload()
            }
        })
        .catch(error => console.error(error));
    }
    return (
        <>
            <ul>
                <li><LabelContents title="결제 상태"><span>미확인</span></LabelContents></li>
                <li><LabelContents title="매물 종류"><span>{data.orderItemType}</span></LabelContents></li>
                <li><LabelContents title="거래소"><span>{data.exchange}</span></LabelContents></li>
                <li><LabelContents title="회원명"><span>{data.customerName}</span></LabelContents></li>
                <li><LabelContents title="위치"><span>{data.orderName}</span></LabelContents></li>
                <li><LabelContents title="결제 아스터코인"><span>{addComma(data.paymentCoinAmount)} 원</span></LabelContents></li>
                <li><LabelContents title="결제 거래소"><span>{data.exchange}</span></LabelContents></li>
                <li><LabelContents title="결제 지갑주소"><span>{data.to}</span></LabelContents></li>
                <li><LabelContents title="주문 시각"><span>{data.insertedTs}</span></LabelContents></li>

                {/* 접기/더보기 버튼 만들기 */}
                <div><button onClick={e=>setMore(!more)}>[ more ]</button></div>
                {
                    more &&
                        <>
                        <li><LabelContents title="회원 휴대폰"><span>{data.mobile}</span></LabelContents></li>
                        <li><LabelContents title="회원 이메일"><span>{data.email}</span></LabelContents></li>
                        <li><LabelContents title="결제 번호"><span>{data.paymentRequestNo}</span></LabelContents></li>
                        <li><LabelContents title="원화 가격"><span>{addComma(data.amount)} 원</span></LabelContents></li>
                        <li><LabelContents title="기준 코인시세"><span>{addComma(data.coinUsd)}</span><br/><span>{data.coinKrw} 원</span></LabelContents></li>
                        <li><LabelContents title="기준 원달러 시세"><span>{addComma(data.wonUsd)} 원</span></LabelContents></li>
                        <li><LabelContents title="주문 번호"><span>{data.orderId}</span></LabelContents></li>
                        </>
                }
            </ul>
            <Button width="100%" onClick={newApprovalSend}>입금 완료 처리</Button>
            <p style={{height:'13px'}}></p>
            <Button width="100%" onClick={newApprovalCancel}>결제 취소 처리</Button>
        </>
    )
}

export default NewApproval