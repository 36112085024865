import React, { useEffect, useState } from "react";
import { isSessionValidate } from "../../apis/browser";
import HeaderTemplate from "./HeaderTemplate";
import Logo from "./Logo";
import Navigation from "./Navigation";

const Header = (props) => {
    const [isSession, setIsSession] = useState(isSessionValidate());

    // useLayoutEffect(()=> {
    //     if(!isSession) {
            
    //     }
    //     console.log(isSession);
    // }, [isSession]);

    return (
        <HeaderTemplate>
            <Logo/>
            <Navigation/>
        </HeaderTemplate>
    );
}

export default Header;