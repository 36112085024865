import RecomMap from './kakaoMap/RecomMap';
import RecomHeader from './RecomHeader';
import Registration from './registration/Registration';
import KakaoClickModal from './recomModal/KakaoClickModal'
import React from 'react';
import { useState } from 'react';
import { axiosGet } from '../../apis/axios';
import { useEffect } from 'react';
import RecomInfoWindow from './recomInfoWindow/RecomInfoWindow';
import './RecomHome.css'
import { searchAddressList } from './kakaoMap/addressListUtil';
import KakaoLoading from './KakaoLoading';


const RecomHome = () => {
    const [keyword, setKeyword] = useState("")
    const [address, setAddress] = useState("")
    // 랜드북 데이터
    const [rendData, setRendData] = useState()
    // 검색 타입
    const [searchType, setSearchType] = useState();
    // 검색목록 클릭시 지번주소
    const [listClickAddress , setListClickAddress] = useState("")
    // 검색목록 클릭시 도로명주소
    const [listClickRoadAddress , setListClickRoadAddress] = useState("")
    // 검색시 최상단 주소값
    const [kakaoSearchAddress , setKakaoSearchAddress ] = useState()
    // 등록 눌렀을때
    const [registModal, setRegistModal] = useState(false)
    // 수정 눌렀을때
    const [retouch, setRetouch] = useState(false)
    // 모달 등록버튼 눌렀을때만 나오고, 나머지 조건은 전부 true
    const [kakaoModalToggle, setKakaoModalToggle] = useState(false)
    // 주소 목록 페이지 정보
    const [page, setPage] = useState(undefined);
    
    // 로딩
    const [kakaoModalLoading,setKakaoModalLoading] = useState(false)

    useEffect(async() => {
        const detail = address.address?.address_name;
        let rendUrl = (detailAddress) => `api/item-management/add-recommended-item/info-json?keyWord=${encodeURI(detailAddress)}`
        // map click이나 검색하거나 검색목록 클릭했을때 각각 type을 별개로 줘서 해당 이벤트에 맞는 주소값을 rendUrl에 요청
        try{
            // 데이터 받기 전 로딩 활성화
            setKakaoModalLoading(true)
            await axiosGet(rendUrl(
                // 맵 클릭시
                searchType === "mapClick" ? detail 
                : 
                // 검색목록 클릭시
                (searchType === "list" && listClickAddress) ?  listClickAddress 
                : 
                // 검색시 최상단 목록 주소
                ((searchType === "search" && !listClickAddress && keyword !== "") && kakaoSearchAddress?.address_name)
            ))
            .then(res=> {
                    setRendData(res.data)
                }
            )
            // 데이터 받고 난 후 로딩 비활성화
            setKakaoModalLoading(false)
        }catch(e){
            console.log(`request item error : ${e}`);
        };
    }, [address , keyword , listClickAddress , kakaoSearchAddress , setKakaoModalLoading  ]);
    

    useEffect(()=>{
            // 카카오에 키워드를 보내서 검색하는 기능 해당 데이터는 page에 담긴다.
        const paginationCallbcak = (data) => {
            setPage(data);
        };
        if(searchType === 'search'){
            searchAddressList(keyword , paginationCallbcak);
        }
    },[keyword ])

    // 리스트 클릭값이 있으면 키워드값을 초기화함, 그 이유는 kakaomap.js에서 주소값을 바탕으로 랜드북에서 위도 경도를 가져오는데, 검색시 최상단 목록의 주소를 넣고 리스트 클릭을 하면 리스트 클릭 값을 넣어 주기 위해서. 
    useEffect(()=>{
        if(listClickAddress){
            setKeyword("")
        }
    },[listClickAddress])

    return (
        <section className='recomHomeContainer'>
            <article className='mapRegistWarp'>
                {/* 검색시 맵클릭했을때 받아온 주소 초기화 */}
                <RecomHeader setKeyword={setKeyword} setAddress={setAddress}  setListClickAddress={setListClickAddress} setKakaoModalToggle={setKakaoModalToggle} setSearchType={setSearchType}></RecomHeader>
                    <RecomMap 
                        keyword={keyword}
                        setAddress={setAddress}  
                        listClickAddress={listClickAddress} 
                        kakaoSearchAddress={kakaoSearchAddress}
                        setSearchType={setSearchType}
                        searchType={searchType}
                        page={page}
                    >
                    </RecomMap>
                <Registration setRetouch={setRetouch} setKakaoModalToggle={setKakaoModalToggle}></Registration>
            </article>

            <article className='recomInfoWarp'>
                <div>
                    <RecomInfoWindow 
                        rendData={rendData}
                        keyword={keyword}
                        address={address}
                        kakaoSearchAddress={kakaoSearchAddress}

                        setKakaoSearchAddress={setKakaoSearchAddress}
                        // 검색목록 클릭시 지번주소
                        setListClickAddress={setListClickAddress}
                        listClickAddress={listClickAddress} 

                        // 검색목록 클릭시 도로명 주소
                        setListClickRoadAddress={setListClickRoadAddress}
                        listClickRoadAddress={listClickRoadAddress}

                        // 등록 눌렀을때
                        setRegistModal={setRegistModal}
                        setKakaoModalToggle={setKakaoModalToggle}

                        // 검색시 받는값
                        page={page}
                        setSearchType={setSearchType}
                        searchType={searchType}
                    >
                    </RecomInfoWindow>
                </div>
                {
                    ((registModal === true || retouch === true) && kakaoModalToggle=== false) && 
                    <>
                        <KakaoClickModal 
                            registModal={registModal} 
                            setRegistModal={setRegistModal}
                            retouch={retouch}
                            setRetouch={setRetouch}
                            address={address}
                            // 검색목록 클릭시 지번주소
                            listClickAddress={listClickAddress}
                            rendData={rendData}

                            // 검색목록 첫번째값
                            kakaoSearchAddress={kakaoSearchAddress}

                            keyword={keyword}
                            searchType={searchType}
                            setKakaoModalLoading={setKakaoModalLoading}
                        >
                        </KakaoClickModal>
                    </>
                    
                }      
                {
                    kakaoModalLoading === true ? 
                    <KakaoLoading>
                    </KakaoLoading>
                    :
                    ""
                }
            </article>
        </section>
    );
};

export default RecomHome;