import React, { useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { toggleMenu } from "../../redux/menu/actions";

const HeaderTemplateForm = styled.header`
    width: 100vw;
    height: 60px;
    display: flex;
    background: #05141F;
`;

const HeaderTemplate = ({children}) => {
    const dispatch = useDispatch();
    const headerRef = useRef();
    const handleClickOutSide = ({ target }) => {
        if(!headerRef.current.contains(target)) {
            dispatch(toggleMenu(true));
        }
    }
    
    useLayoutEffect(() => {
        window.addEventListener("click", handleClickOutSide);
        return () => {
            window.removeEventListener("click", handleClickOutSide);
        }
    });
    

    return (
        <HeaderTemplateForm ref={headerRef}>
            {children}
        </HeaderTemplateForm>
    );
}

export default HeaderTemplate;