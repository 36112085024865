import React from "react";
import PageTemplate from "../components/PageTemplate";

const Main = () => {
    return (
        <PageTemplate>
            main page
        </PageTemplate>
    );
}

export default Main;