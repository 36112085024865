import { axiosPost } from "./axios";

export const isSessionValidate = () => {
    const target = 'adminSessionId';
    return localStorage.getItem(target) !== null && typeof localStorage.getItem(target) !== 'undefined' && localStorage.getItem(target) !== 'undefined';
}

export const removeCookieAPI = async () => {
    axiosPost('api/user/removeSessionId')
        .then(response => {
            if(JSON.parse(response.data.result)) {
                // console.log(response.data);                
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}

