import React, { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { axiosGet} from "../../../apis/axios";
import SupportTemplate from "../../../components/SupportTemplate";
import UploadData from "../../../components/UploadData";
import ItemTemplate from "../../../components/ItemTemplate";

const SubTitle = styled.legend`
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
`;


const HotIssuesTemplate = styled.fieldset`
    width: 100%;
    max-width: 777px;
    border: 1px solid #ccc;
    padding: 40px;
    margin-bottom: 50px;
`;

const HotIssues = () => {
    const [items, setItems] = useState([]);

    useLayoutEffect(()=>{
        getVideoList();
    },[]);

    const getVideoList = () => {
        axiosGet('hotIssue/select?pageNum=1&numPerPage=1000000')
            .then(response => {
                if(JSON.parse(response.data.result)){
                    setItems(response.data.data);
                    return;
                }
                alert(response.data.message);
            })
            .catch(error => {
                console.error(error);
            })
    }




    return (
        <SupportTemplate>
            <h1>부동산 핫이슈</h1>
            <div>
                <UploadData subTitle="핫이슈" requestUrl='hotIssue' />
                <HotIssuesTemplate>
                    <SubTitle>핫이슈 목록</SubTitle>
                    {
                        items.length !== 0 &&
                        items.map((el, index) => <ItemTemplate 
                                                    key={index} 
                                                    no={el.boardMetarexVideoNo ? el.boardMetarexVideoNo : el.boardHotIssueNo} 
                                                    title={el.subjectKr} 
                                                    date={el.pressDate || ''}
                                                    uri={el.uri ? el.uri : el.link}
                                                    resourceUrl="hotIssue"/>)
                    }
                </HotIssuesTemplate>
            </div>
        </SupportTemplate>
    );
}

export default HotIssues;