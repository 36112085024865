import React, { useLayoutEffect, useState } from "react";
import queryString from 'query-string';
import { axiosGet, axiosPost } from "../../apis/axios";
import RichTextEditor from "../../components/textEditor/RichTextEditor";
import InputBox from "../../components/textEditor/InputBox";
import SupportTemplate from "../../components/SupportTemplate";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { isNull } from "../../apis/common";

const EditNoticeTemplate = styled.div`
    display: block;
    width: 100%;
    max-width: 777px;
    margin-bottom: 70px;
`;

const ButtonTemplate = styled.div`
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const EditNotice = () => {
    const [detail, setDetail] = useState();
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const parsed = queryString.parse(window.location.search);
    const url = parsed.type;

    const navigate = useNavigate();

    useLayoutEffect(()=>{
        const boardNo = parsed.boardNo;
        getNoticeDetail(boardNo);
    }, [parsed.boardNo]);


    
    const getNoticeDetail = (idx) => {
        axiosGet(`${url}/detail/select?board=${url}&idx=${idx}`)
            .then(response => {
                if(JSON.parse(response.data.result)){
                    const detail = response.data.data;
                    setDetail(detail);
                    setTitle(detail.subjectKr);
                    setText(detail.bodyKr);
                    return;
                }
                alert(response.data.message);
            })
            .catch(error=> {
                console.log(error)
            });
    }

    const updateData = () => {
        let type;
        let boardNo;
        if(!isNull(detail.boardNoticeNo)){
            type = 'boardNoticeNo';
            boardNo = detail.boardNoticeNo;
        } else {
            type = 'boardMetarexFaqNo';
            boardNo = detail.boardMetarexFaqNo;
        }
        // const boardNo = detail.boardNoticeNo ? detail.boardNoticeNo : detail.boardMetarexFaqNo;

        axiosPost(`${url}/update`, {
            'markupStr': text,
            'subjectKr': title,
            [type]: boardNo
        })
            .then(response => {
                alert(response.data.message);
                if(JSON.parse(response.data.result)){
                    navigate(`/${url}`);
                    return;
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <SupportTemplate>
            <h1>글 수정하기</h1>
            {detail &&
                <div>
                    <EditNoticeTemplate>
                        <InputBox type="text" placeholder="제목" item={title} setItem={setTitle} />
                        <RichTextEditor item={text} setText={setText} />
                    </EditNoticeTemplate>
                    <ButtonTemplate>
                        <Link to={`/${url}`}><Button width="155px">취소</Button></Link>
                        <Button width="155px" onClick={updateData}>수정</Button>
                    </ButtonTemplate>
                </div>
            }
        </SupportTemplate>
    );
}

export default EditNotice;