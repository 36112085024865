import { axiosGet } from "../../../apis/axios";
const { kakao } = window; 

export default function kakaoMap(keyword,setAddress,listClickAddress , kakaoSearchAddress , setSearchType , searchType , page) {

    var container = document.getElementById('map'); // 지도를 표시할 div 
   // 카카오맵을 로드하기전 카카오맵을 세팅할 태그의 자식 요소들을 모두 제거한다.
    while(container.firstChild) {
        container.removeChild(container.firstChild);
    }
    const polyline = new kakao.maps.Polygon({
        // path값이 값이 그려질 좌표, 빈 배열값으로 준뒤 setPath값으로 전달.
        path:[], // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 3, // 선의 두께입니다
        strokeColor: '#1E90FF', // 선의 색깔입니다
        strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다

        // kakaomap.jsx에서 받아온 데이터값을 토대로. 구매매물이면 무슨색, 테마매물이면 무슨색 이런식으로 조건문 작성.
        fillColor: '#ACF3FF', // 채우기 색깔입니다
        fillOpacity: 0.3 // 채우기 불투명도 입니다
    });
    
    // 카카오맵 초기값
    const options = {
        // center 의 LatLng값을 바꿔주면 초기 위도경도값을 변경할수있다.
        center: new kakao.maps.LatLng(37.48647151051701, 127.02065165954758),
        // 지도 확대 정도
        level: 2
    };
    let map = new kakao.maps.Map(container, options);

    // 주소-좌표 변환 객체를 생성합니다
    let geocoder = new kakao.maps.services.Geocoder();

    
    // map 기본타입을 위성사진으로
    map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
    // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
    var mapTypeControl = new kakao.maps.MapTypeControl();
    // 지도에 컨트롤을 추가해야 지도위에 표시됩니다
    // kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

    // 지도를 클릭했을 때 클릭 위치 좌표에 대한 주소정보를 표시하도록 이벤트를 등록합니다
    kakao.maps.event.addListener(map, 'click',  function(mouseEvent , searchType) {
        searchDetailAddrFromCoords(geocoder, mouseEvent.latLng, async function(result, status) {
            if (status === kakao.maps.services.Status.OK) {
                // 도로명 주소가 없는 경우 라인셋, 마커, 상세정보 출력 등의 처리를 하지 않음. 
                if(result[0].road_address === null) {
                    return;
                }
                // 맵 클릭했을때 타입을 mapClick으로 변경해준다.
                setSearchType("mapClick")
                // 맵 클릭시 주소값을 setAddress안에 넣어준다.
                setAddress(result[0]);

                // 맵 클릭시 주소값과 searchType을 라인 칠해주는 함수에 전달
                const detail = !!result[0].address ? result[0].address.address_name : '  ';
                lineSet( map, detail, polyline ,null , null , searchType)
            }
        });
    });

    lineSet( map, keyword, polyline ,listClickAddress , kakaoSearchAddress , searchType , page)
    
    return {
        changeLineSet: keyword => lineSet( map, keyword, polyline ,listClickAddress , kakaoSearchAddress , searchType)
    };
}

/**
 * 좌표로 법정동 상세 주소 정보를 요청합니다
 */
function searchDetailAddrFromCoords(geocoder, coords, callback) {
    geocoder.coord2Address(coords.getLng(), coords.getLat(), callback);
}

/**
 * 카카오맵 매물 테두리 및 이동
 */
const lineSet =  async ( map, keyword, polyline ,listClickAddress , kakaoSearchAddress , searchType , page) => {

    if(page){
        if(searchType === "search"){
            // 검색시 검색목록이 여러개면 최상단 주소를, 하나면 하나만 kakaoMapPanto에 전달해준다. 그로써 포커싱
            kakaoMapPanTo(map,  page?.data[0] ? page?.data[0]?.y :  page?.data?.y , page?.data[0] ? page?.data[0]?.x : page?.data?.x)
        }else if(searchType === "list"){
            // 검색목록 클릭시에 위도,경도값으로 kakaoMapPanTo
            page?.data.filter(data =>  data.address_name  === listClickAddress && kakaoMapPanTo(map , data?.y, data?.x))
        }
    }

    let url = (keyword) => `api/item-management/add-recommended-item/info-json?keyWord=${keyword}`
    // searchType에 따라 검색시 최상단 목록의 주소나, 목록 클릭시 주소값을 요청한다.
    let res = await axiosGet(url(
        (searchType === "search") ? (kakaoSearchAddress && kakaoSearchAddress.address_name ) 
        :
        ((searchType === "list" && listClickAddress) || (searchType !== "search" && searchType !== "list") && keyword )
    ))

    if(!res.estimated_price){
        const other_parcel = res.data.estimated_price?.other_parcel
        let focusingCoord       = null;
        let totalLinePath       = [];
        
        // 랜드북에서 영역이 여러개 곂쳐져 있을때 값을 전달해주는 로직
        for( let idx in other_parcel) {
            const coord = res.data.estimated_price.other_parcel[idx].geometry?.coordinates[0];
            const linePath = coord.map( ([x,y]) => {
                return new kakao.maps.LatLng(y,x);
            });
            totalLinePath.push(linePath);
            focusingCoord = coord;
        }

        if(res !== null){
            // 영역이 그려질 좌표, setPath(kakao에서 제공하는 api)를 이용해 해당 위도 경도값을 전달.
            polyline.setPath(totalLinePath);
            polyline.setMap(map);
            // 검색시 호출되는 page가 없을때, 즉 맵 클릭시 포커스 시켜주는 panto에 위도 경도값을 전달
            if(!page && focusingCoord){
                kakaoMapPanTo(map, focusingCoord[0][1], focusingCoord[0][0]);
            }
        }else {
            polyline.setMap(null);
        }

        // 랜드북에서 영역이 하나일때 값을 전달해주는 로직
    } else if(res.data.parcel_info) {
        let coord = res.data.parcel_info.geometry.coordinates[0];
        const linePath = coord.map( ([x,y]) => {
            return new kakao.maps.LatLng(y,x)
        });

        if(res !== null){
            // 영역이 그려질 좌표, setPath(kakao에서 제공하는 api)를 이용해 해당 위도 경도값을 전달.
            polyline.setPath(linePath);
            polyline.setMap(map);
            if(!page){
                kakaoMapPanTo(map, coord[0][1], coord[0][0]);
            }
        }else {
            // 지도에 그려진 영역을 초기화
            polyline.setMap(null);
        }
    } else if(listClickAddress !== "") {
        alert("랜드북 데이터가 없습니다.")
        polyline.setMap(null);
    }

};

// 해당 위도 경도값에 포커싱해주는 로직
const kakaoMapPanTo = (map, lat, lng) => {
    let moveLatLng = new kakao.maps.LatLng(lat , lng );
    map.panTo(moveLatLng);
};