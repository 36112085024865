import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { axiosGet } from "../../apis/axios";
import PageTemplate from "../../components/PageTemplate";
import ItemCard from "../../components/payment/ItemCard";
import NewHistory from "../../contents/payments/NewHistory";
import { MOCK_DATA_FOR_P2P_REQUEST } from "../../mockData";

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    >p{
        display: inline;
    }
`;
const HistoryInput = styled.div`
    justify-content: center;
    align-items: center;
    width: 350px;
    margin: 0 auto;
    >input{
        display:inline-block;
        width:100%;
        height: 28px;
        margin-bottom : 10px
    }
`;

const ItemPaymentHistory = () => {
    const [newPaymentText, setNewPaymentText] = useState("");

    return (
        <PageTemplate>
            <TitleDiv>                
                <p>new 매물 결제 내역</p>
                <span>결제가 완료된 내역입니다.</span>
            </TitleDiv>
            <HistoryInput>
                <label>검색창</label>
                <input onChange={e => setNewPaymentText(e.target.value)}></input>
            </HistoryInput>
            {/* {
                newHistoryList?.result === 'true' &&
                newHistoryList?.data.map((el,index) => <ItemCard key={index}><NewHistory data={el}/></ItemCard>)
            } */}
        </PageTemplate>
    );
}

export default ItemPaymentHistory;