import React, { useLayoutEffect, useState } from "react";
import { axiosGet } from "../../../apis/axios";
import Board from "../../../components/board/Board";
import SupportTemplate from "../../../components/SupportTemplate";

const Faq = () => {
    const PAGE_PER_NUM = 5;
    const [faq, setFaq] = useState([]);
    const [pageNum, setPageNum] = useState(1);

    useLayoutEffect(() => {
        getFaqList();
    }, []);

    // FAQ 리스트 불러오는 API 작성하기
    const getFaqList = () => {
        axiosGet('faq/select')
            .then(response => {
                setFaq(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <SupportTemplate>
            <h1>FAQ</h1>
            <div>
                {faq.length !== 0 &&
                    <Board dataList={faq} pageNum={pageNum} setPageNum={setPageNum} totalCount={faq.length} PAGE_PER_NUM={PAGE_PER_NUM} url="/faq"/>
                }
            </div>
        </SupportTemplate>
    );
}

export default Faq;